import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { StateInterface } from 'src/app/shared/interface/tab-interface/state.interface';
import { stateList } from 'src/app/store/selectors/admin.selectors';
import { select, Store } from '@ngrx/store';
import { Helper } from 'src/app/shared/classes/helpers.class';
import { Validations } from 'src/app/constants/validations';
import { IAppState } from 'src/app/store/state/admin/app.state';
import { FormService } from 'src/app/services/forms/form.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CellComp } from 'ag-grid-community';



@Component({
  selector: 'app-add-new-parent',
  templateUrl: './add-new-parent.component.html',
  styleUrls: ['./add-new-parent.component.css']
})
export class AddNewParentComponent implements OnInit {

  addNewParent: FormGroup;
  stateList$: Observable<StateInterface> = this._store.pipe(select(stateList));
  helper: Helper = new Helper();
  id: any;
  store: any;
  route: any;
  newUser$: any;
  shareUtil: any;
  validMsg = Validations;
  valid = Validations;
  selectedLink: string;
  isAdd: boolean;
  editText: string;
  isParent = false;
  editId = 0;
  value: any;
  checked: any;
  isEdit = false;
  constructor(private _store: Store<IAppState>, public fs: FormService,
    private dialogRef: MatDialogRef<AddNewParentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) {
    this.addNewParent = fs.addNewParent();
  }
  ngOnInit() {
    this.formDisable();
  }

  formDisable() {
    if (this.data['status'] == 'add_parent' || this.data['status'] == 'edit_parent') {
      if (this.data['status'] == 'edit_parent') {
        this.isEdit = true;
        this.addNewParent.patchValue({
          parentCompany: this.data['value']['name']
        })
      }
      this.isParent = false;
      this.addNewParent.get('childCompany').disable();
    } else if (this.data['status'] == 'add_child' || this.data['status'] == 'edit_child') {
      this.isParent = true;
      if (this.data['status'] == 'edit_child') {
        this.isEdit = true;
        this.addNewParent.patchValue({
          parentCompany: this.data['value']['parent_name'],
          childCompany: this.data['value']['name']
        })
      } else{
        this.isEdit = false;
        this.addNewParent.patchValue({
          parentCompany: this.data['value']['name']
        })
      }
      this.addNewParent.get('parentCompany').disable();
    }
  }

  close(obj) {
    this.dialogRef.close(obj);
  }

  get f() { return this.addNewParent.controls; }

  add() {
    const addObj = this.helper.formData(this.addNewParent);
    this.close(addObj);
  }

  cancel() {
    this.isAdd = true;
  }



}

