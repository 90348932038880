import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoreService } from 'src/app/services/core.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable()
export class AdminService extends CoreService {
  public API_ENDPOINT;
  public env;
  //public API_ENDPOINT;

  constructor(public http: HttpClient) {
    super(http);
    let self = this;
    if (localStorage.getItem("configData")) {
      self.env = JSON.parse(localStorage.getItem("configData"));
      self.API_ENDPOINT = self.env.API_ENDPOINT;
    }
  }
  syncConfig(){
    let self = this;
    if (localStorage.getItem("configData")) {
      self.env = JSON.parse(localStorage.getItem("configData"));
      self.API_ENDPOINT = self.env.API_ENDPOINT;
    }
  }

  /**
   *  @service `Get  Orders`
   *  @parmas : added `Obj as user id or Client Id`
   *  `Namespace` : @getAgents
   */
  getOrders(reqObj): Observable<any> {
    return this.get(`order-list.json`);
  }
  /**
  *  @service `get client`
  *  @parmas : added `Obj as  Client Id`
  *  `Namespace` : @get Client
  */
  getClientInfo(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `AdminTransaction/getClientInfo?clientKey=${reqObj['clientKey']}&companyKey=${reqObj['companyKey']}`);
  }

  /**
   *  @service `Update  Orders`
   *  @parmas : added `Obj as user id or Client Id`
   *  `Namespace` : @update Client
   */
  updateClient(reqObj): Observable<any> {
    return this.post(`update-client`, reqObj);
  }

  /**
   *  @service `Update  Client Valid Status`
   *  @parmas : added `Obj as user id or Client Id`
   *  `Namespace` : @update Client
   */
  updateValid(reqObj): Observable<any> {
    return this.post(`update-status`, reqObj);
  }

  /**
   *  @service `Update Tab Method`
   *  @parmas : added `Obj as user id or Client Id`
   *  `Namespace` : @update Client
   */
  updateTab(methodName, reqObj) {
    return this.post(`${methodName}`, reqObj);
  }





  /**
   *  @service `Get  Insurance List`
   *  @parmas : added `Obj as user id or Client Id`
   *  `Namespace` : @getAgents
   */
  getEmailRecord(reqObj): Observable<any> {
    return this.get(`email_record.json`);
  }
  rushOrderConvert(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `AdminTransaction/ConvertToRushOrder?transactionKey=${reqObj['transactionKey']}&userKey=${reqObj['userKey']}&clientKey=${reqObj['clientKey']}&loggedInUser=${reqObj['loggedInUser']}`);
  }

  getOrderStatus(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `AdminTransaction/GetOrderStatus?userKey=${reqObj['userKey']}&userType=${reqObj['userType']}&fromDate=${reqObj['fromDate']}&toDate=${reqObj['toDate']}&report=${reqObj['report']}`);
  }

  getDocumentStatus(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `AdminTransaction/GetDocumentStatus?userKey=${reqObj['userKey']}&userType=${reqObj['userType']}&fromDate=${reqObj['fromDate']}&toDate=${reqObj['toDate']}&report=${reqObj['report']}`);
  }

  getDashboardTransactionList(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `AdminTransaction/getDashboardTransactionList?userKey=${reqObj['userKey']}&userType=${reqObj['userType']}&fromDate=${reqObj['fromDate']}&toDate=${reqObj['toDate']}&report=${reqObj['report']}&status=${reqObj['status']}&docType=${reqObj['docType']}`);
  }

  GetExistingOrderList(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `AdminTransaction/GetExistingOrderList`, reqObj);
  }

  getOrderDetails(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `AdminTransaction/getOrderDetailsByOrderId?userKey=${reqObj['userKey']}&userType=${reqObj['userType']}&orderKey=${reqObj['orderKey']}&clientKey=${reqObj['clientKey']}`);
  }

  getUnderwriterList(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `AdminTransaction/getUnderwriterList?clientKey=${reqObj['clientKey']}&userKey=${reqObj['userKey']}`);
  }

  getClientList(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `AdminTransaction/getClientList?clientKey=${reqObj['clientKey']}&userKey=${reqObj['userKey']}`);
  }

  getClosingAgentList(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `AdminTransaction/getClosingAgentList?clientKey=${reqObj['clientKey']}&userKey=${reqObj['userKey']}`);
  }

  getWireInfoList(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `AdminTransaction/getWireInfoList?clientKey=${reqObj['clientKey']}&titleKey=${reqObj['titleKey']}&companyKey=${reqObj['companyKey']}&userKey=${reqObj['userKey']}`);
  }

  getUploadedDocList(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `AdminTransaction/getUploadedDocList?clientKey=${reqObj['clientKey']}&orderKey=${reqObj['orderKey']}&userKey=${reqObj['userKey']}&loggedInUser=${reqObj['loggedInUser']}`);
  }

  uploadDoc(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `AdminTransaction/uploadDoc`, reqObj);
  }

  getNotes(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `AdminTransaction/getNotes?clientKey=${reqObj['clientKey']}&transactionKey=${reqObj['transactionKey']}&userKey=${reqObj['userKey']}`);
  }

  addNote(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `AdminTransaction/addNote`, reqObj);
  }

  updateDocStatus(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `AdminTransaction/updateDocStatus`, reqObj);
  }

  getTransactionErrorList(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `AdminTransaction/getTransactionErrorList?clientKey=${reqObj['clientKey']}&userKey=${reqObj['userKey']}`);
  }

  updateTransactionError(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `AdminTransaction/updateTransactionError?clientKey=${reqObj['clientKey']}&orderKey=${reqObj['orderKey']}&errorKey=${reqObj['errorKey']}&userKey=${reqObj['userKey']}`);
  }

  updateClientInfo(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `AdminTransaction/UpdateClientInfo?clientKey=${reqObj['clientKey']}&orderKey=${reqObj['orderKey']}&lenderKey=${reqObj['companyKey']}&userKey=${reqObj['userKey']}`);
  }
  updateClosingAgent(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `AdminTransaction/UpdateClosingAgent?clientKey=${reqObj['clientKey']}&orderKey=${reqObj['orderKey']}&userKey=${reqObj['userKey']}`, reqObj);
  }
  selectClosingAgent(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `AdminTransaction/SelectClosingAgent?clientKey=${reqObj['clientKey']}&orderKey=${reqObj['orderKey']}&userKey=${reqObj['userKey']}&companyKey=${reqObj['companyKey']}`);
  }

  updateBorrower(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `AdminTransaction/updateBorrower?orderKey=${reqObj['transactionKey']}&userKey=${reqObj['userKey']}&clientKey=${reqObj['clientKey']}`, reqObj);
  }

  updateProperty(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `AdminTransaction/updateProperty?orderKey=${reqObj['orderKey']}&userKey=${reqObj['userKey']}&clientKey=${reqObj['clientKey']}`, reqObj);
  }

  updateUnderwriter(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `AdminTransaction/updateUnderwriter?orderKey=${reqObj['orderKey']}&userKey=${reqObj['userKey']}&clientKey=${reqObj['clientKey']}`, reqObj);
  }

  updateLoanDetails(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `AdminTransaction/updateLoanDetails?orderKey=${reqObj['orderKey']}&userKey=${reqObj['userKey']}&clientKey=${reqObj['clientKey']}`, reqObj);
  }
  updateCPLDate(reqObj): Observable<any> {
    let url = "";
    if (reqObj['CplDate'] != "" && reqObj['CplDate'] != null) {
      url = this.API_ENDPOINT + `AdminTransaction/UpdateCPLDate?clientKey=${reqObj['clientKey']}&orderKey=${reqObj['orderKey']}&CplDate=${reqObj['CplDate']}&userKey=${reqObj['userKey']}`;
    } else {
      url = this.API_ENDPOINT + `AdminTransaction/UpdateCPLDate?clientKey=${reqObj['clientKey']}&orderKey=${reqObj['orderKey']}&userKey=${reqObj['userKey']}`;
    }
    return this.patch(url, null);
   }
  updateTransactionStatus(reqObj): Observable<any> {
    let url = "";
    if (reqObj['memoDate'] != "" && reqObj['memoDate'] != null && reqObj['memo'] != "" && reqObj['memo'] != null) {
      url = this.API_ENDPOINT + `AdminTransaction/UpdateTransactionStatus?clientKey=${reqObj['clientKey']}&orderKey=${reqObj['orderKey']}&SectionType=${reqObj['SectionType']}&StatusType=${reqObj['StatusType']}&LoggedInUser=${reqObj['LoggedInUser']}&memo=${reqObj['memo']}&memoDate=${reqObj['memoDate']}`;
    }
    else if (reqObj['memo'] != "" && reqObj['memo'] != null) {
      url = this.API_ENDPOINT + `AdminTransaction/UpdateTransactionStatus?clientKey=${reqObj['clientKey']}&orderKey=${reqObj['orderKey']}&SectionType=${reqObj['SectionType']}&StatusType=${reqObj['StatusType']}&LoggedInUser=${reqObj['LoggedInUser']}&memo=${reqObj['memo']}`;
    } else if (reqObj['memoDate'] != "" && reqObj['memoDate'] != null) {
      url = this.API_ENDPOINT + `AdminTransaction/UpdateTransactionStatus?clientKey=${reqObj['clientKey']}&orderKey=${reqObj['orderKey']}&SectionType=${reqObj['SectionType']}&StatusType=${reqObj['StatusType']}&LoggedInUser=${reqObj['LoggedInUser']}&memoDate=${reqObj['memoDate']}`;
    } else {
      url = this.API_ENDPOINT + `AdminTransaction/UpdateTransactionStatus?clientKey=${reqObj['clientKey']}&orderKey=${reqObj['orderKey']}&SectionType=${reqObj['SectionType']}&StatusType=${reqObj['StatusType']}&LoggedInUser=${reqObj['LoggedInUser']}`;
    }
    return this.post(url, null);
  }
  setCPLVerification(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `AdminTransaction/SetCPLVerification?clientKey=${reqObj['clientKey']}&orderKey=${reqObj['orderKey']}&userKey=${reqObj['userKey']}&statusToSet=${reqObj['statusToSet']}`, reqObj);
  }

  selectNewWire(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `AdminTransaction/SelectNewWire?clientKey=${reqObj['clientKey']}&orderKey=${reqObj['orderKey']}&userKey=${reqObj['userKey']}&accountKey=${reqObj['accountKey']}`);
  }

  updateWireAndInsuranceInfo(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `AdminTransaction/updateWireAndInsuranceInfo?orderKey=${reqObj['orderKey']}&userKey=${reqObj['userKey']}&clientKey=${reqObj['clientKey']}`, reqObj);
  }

  sendDocument(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `AdminTransaction/sendDocument?clientKey=${reqObj['clientKey']}`, reqObj);
  }

  certificateRegenerate(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `AdminTransaction/CertificateRegenerate?clientKey=${reqObj['clientKey']}&orderKey=${reqObj['orderKey']}&userKey=${reqObj['userKey']}`, reqObj);
  }

  setCertificationStatus(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `AdminTransaction/SetCertificationStatus?clientKey=${reqObj['clientKey']}&orderKey=${reqObj['orderKey']}&certificationSent=${reqObj['certificationSent']}&userKey=${reqObj['userKey']}`);
  }

  getOrderKeyByLoanNumber(reqObj): Observable < any > {
    return this.get(this.API_ENDPOINT + `AdminTransaction/GetOrderKeyByLoanNumber?userKey=${reqObj['userKey']}&clientKey=${reqObj['clientKey']}&loanNumber=${reqObj['loanNumber']}`);
  }



  selectNewUnderwriter(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `AdminTransaction/SelectNewUnderwriter?clientKey=${reqObj['clientKey']}&orderKey=${reqObj['orderKey']}&userKey=${reqObj['userKey']}&companyKey=${reqObj['companyKey']}`);
  }
  getEmailTemplate(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `AdminTransaction/getEmailTemplate?orderKey=${reqObj['orderKey']}&userKey=${reqObj['userKey']}`);
  }

  insertBankInfo(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `/AdminTransaction/InsertBankInfo?clientKey=${reqObj['clientKey']}&userKey=${reqObj['userKey']}`, reqObj);
  }

  updateinsuranceInfo(reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `/AdminTransaction/UpdateInsuranceInfo?clientKey=${reqObj['clientKey']}`, reqObj);
  }

  //Select Title Company:
   getTitleCompanyList(reqObj): Observable < any > {
      return this.get(this.API_ENDPOINT + `AdminTransaction/GetTitleCompanyList?clientKey=${reqObj['clientKey']}`);
  }

  getInsuranceInfoById(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `AdminTransaction/GetInsuranceInfoById?companyKey=${reqObj['companyKey']}&policyKey=${reqObj['policyKey']}&userKey=${reqObj['userKey']}`);
  }
  //Full Page
  getTitleInfo(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `AdminTransaction/GetTitleInfo?companyKey=${reqObj['companyKey']}&userKey=${reqObj['userKey']}`);
  }

  getBankAccountInfoById(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `AdminTransaction/GetBankAccountInfoById?companyKey=${reqObj['companyKey']}&bankKey=${reqObj['bankKey']}&userKey=${reqObj['userKey']}`);
  }

  getInsuranceInformationCount(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `AdminTransaction/GetInsuranceInformationCount?companyKey=${reqObj['companyKey']}&userKey=${reqObj['userKey']}`);
  }
  checkOfac(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `AdminTransaction/Button_RunOFAC_Click?clientKey=${reqObj['clientKey']}&orderKey=${reqObj['orderKey']}&userKey=${reqObj['userKey']}`, reqObj);
  }


  /**
  *  @service `Get  NotesList`
  *  @parmas : added `Obj as user id or Client Id`
  *  `Namespace` : @getAgents
  */
  getFiltersList(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `Filter/GetFilters?createdby=${reqObj['userKey']}`);
  }

  saveFilter(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `Filter/AddFilter`, reqObj);
  }


  deleteFilter(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `Filter/RemoveFilter`, reqObj);
  }
  }

