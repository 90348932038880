import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { of } from 'rxjs';
import { Helper } from '../../classes/helpers.class';
declare var flatpickr;
@Component({
    selector: 'app-custom-date-component',
    templateUrl: './custom-date-component.component.html',
    styleUrls: ['./custom-date-component.component.css']
})
export class CustomDateComponentComponent implements AfterViewInit {
    helper = new Helper();
    @ViewChild("flatpickrEl", { read: ElementRef, static: false }) flatpickrEl: ElementRef;
    private date: Date;
    private params: any;
    private picker: any;
    flatpickr: any;
    datePciker$ = of(this.helper.dateFormate());
    agInit(params: any): void {
        this.params = params;
    }

    ngAfterViewInit(): void {
        this.picker = flatpickr(this.flatpickrEl.nativeElement, {
            onChange: this.onDateChanged.bind(this),
            wrap: true
        });

        if( this.picker.calendarContainer) {
            this.picker.calendarContainer.classList.add('ag-custom-component-popup');
        }
        
    }

    ngOnDestroy() {
        
    }

    onDateChanged(selectedDates) {
        this.date = selectedDates[0] || null;
        this.params.onDateChanged();
    }

    getDate(): Date {
        return this.date;
    }

    setDate(date: Date): void {
        this.date = date || null;
        this.picker.setDate(date);
    }

}


