
export const environment = {
  // Login
   production: false,
   EndpointCode: "dcfa18b2-c330-4d5f-bdba-ea8ecc3302b6",
   // EndpointLogin: "https://app-identityservice-dev-eastus.azurewebsites.net",
   EndpointLogin: "https://localhost:5000",
   EndpointEntity: "fs.admin.ui",

 };

