import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-company-hierarchy',
  templateUrl: './add-company-hierarchy.component.html',
  styleUrls: ['./add-company-hierarchy.component.css']
})
export class AddCompanyHierarchyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
