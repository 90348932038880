import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { EditComponent } from './edit/edit.component';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FileIconComponent } from './file-icon/file-icon.component';
import { CustomDateComponentComponent } from './custom-date-component/custom-date-component.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { ClientRoleEditComponent } from './client-role-edit/client-role-edit.component';
import { ClientRoleInnerGridEditComponent } from './client-role-inner-grid-edit/client-role-inner-grid-edit.component';
import { ToolTipComponent } from '../pages/tool-tip/tool-tip.component';




@NgModule({
  declarations: [EditComponent, FileIconComponent, CustomDateComponentComponent, EditUserComponent, ClientRoleEditComponent, ClientRoleInnerGridEditComponent],
  imports: [
    CommonModule,
    AgGridModule.withComponents([CustomDateComponentComponent, ToolTipComponent]),RouterModule
  ],
  entryComponents:[EditComponent,FileIconComponent,CustomDateComponentComponent,EditUserComponent],
  exports: [AgGridModule]
})
export class AgGridMyModule { }
