import { Injectable } from '@angular/core';
import { CoreService } from 'src/app/services/core.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends CoreService {
  public API_ENDPOINT;
//public API_ENDPOINT;
  constructor(public http: HttpClient) {
    super(http);
    let self = this;
    self.API_ENDPOINT;
  }

  /**
   * @method: getState
   * @return `List of State`
   */
  getState(): Observable<any> {
    return this.get(`assets/api/state.json`)
  }
  /**
*  @service `Get  all userdetaillist`
*  @parmas : added `Obj `
*  `Namespace` : @getUserDetailList
*/
  getUserDetail() {
    return this.get(this.API_ENDPOINT + `User/GetUserDetailList`);
  }
  /**
*  @service `Get  all getUserDetailByUserId`
*  @parmas : added `Obj `
*  `Namespace` : @getUserDetailList
*/
  getUserDetailByUserId(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetUserDetailListByUserId?clientKey=${reqObj["clientKey"]}&userKey=${reqObj["userKey"]}`);
  }
  /**
*  @service `Get  all company name`
*  @parmas : added `Obj `
*  `Namespace` : @getUserDetailList
*/
  getCompanyName(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetCompanyName?clientKey=${reqObj["clientKey"]}&companykey=${reqObj["companykey"]}$i=${reqObj["i"]}`);
  }
  /**
*  @service `Get  all role name`
*  @parmas : added `Obj `
*  `Namespace` : @getRoleName
*/
  getRoleName(): Observable<any> {
    return this.get(this.API_ENDPOINT + `User/GetRoleName`);
  }



  addUser(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `User/AddNewUser`, reqObj);
  }
  addClient(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `User/AddNewClient`, reqObj);
  }

  updateUser(reqObj,userKey): Observable<any> {
    return this.patch(this.API_ENDPOINT + `User/UpdateUserDetail?userKey=${userKey}`, reqObj);
  }
}
