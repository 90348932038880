import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { StateInterface} from 'src/app/shared/interface/tab-interface/state.interface';
import { Observable, of } from 'rxjs';
import { IAppState } from 'src/app/store/state/admin/app.state';
import { Helper } from 'src/app/shared/classes/helpers.class';
import { FormService } from 'src/app/services/forms/form.service';
import { Validations } from 'src/app/constants/validations';
import { UsersService } from '../../users/users.service';
import { stateList } from 'src/app/store/selectors/admin.selectors';
import { CellComp } from 'ag-grid-community';

@Component({
  selector: 'app-add-new-user',
  templateUrl: './add-new-user.component.html',
  styleUrls: ['./add-new-user.component.css']
})
export class AddNewUserComponent implements OnInit {
  addNewUser: FormGroup;
  stateList$: Observable<StateInterface> = this._store.pipe(select(stateList));
  helper: Helper = new Helper();
  id: any;
  store: any;
  route: any;
  newUser$: any;
  shareUtil: any;
  validMsg = Validations;
  valid = Validations;
  titleText = "Create New User";
  isUpdate = false;
  userKey;
  roleArray$: Observable<any>;
  constructor(private _store: Store<IAppState>, public fs: FormService,
              private dialogRef: MatDialogRef<AddNewUserComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public userApi: UsersService) {
    this.addNewUser = fs.addNewUser();
  }
  ngOnInit() {
    if (this.data) {
      this.titleText = "Edit User";
      this.isUpdate = true;
      this.userKey = this.data['userKey'];
      this.setForm();
    }
    this.getRoleName();
  
  }

 close(obj) {
    this.dialogRef.close(obj);
  } 
  agInit(params){
    this.data = params[['data']['edit_id']];
  
  }
  get f() { return this.addNewUser.controls; }
 

  add() {
    const addObj = this.helper.formData(this.addNewUser);
    console.error(addObj);
    if (this.isUpdate) {
      // For Update Condition api
      this.updateApi(addObj);
    } else {
      // API For Create ...
      this.createApi(addObj);
    }
    //this.close(addObj);
  }
  getRoleName() {
    this.userApi.getRoleName().subscribe((result) => {
      if (this.helper.success(result)) {
        //userrole: this.data['roleKey']
//this.userRoleList$=of(this.helper.response(result));
        this.roleArray$ = of(this.helper.response(result));
        console.log(this.helper.response(result))
      }
    }, (error) => {
//console.log(this.helper.response(result))
      this.data = of([]);
    })
  }

  setForm(){
    if (this.data) {
      this.addNewUser.patchValue({
        firstName: this.data['userFirstName'],
        lastName: this.data['userLastName'],
        email: this.data['userEmail'],
        phone: this.data['userFirstName'],
        mobile: this.data['userMobile'],
        address: this.data['userAddress'],
        address1: this.data['userAddress2'],
        city:  this.data['userCity'],
        state: this.data['userState'],
        userrole: this.data['userRole'],
      })
    }   
  }


  updateApi(reqData) {
    this.userApi.updateUser(reqData,this.userKey).subscribe(value => {
      if (value && value["status"] == "Success") {
        this.success(this.helper.response(value), 'update_user');
      }
      
    }, error => {
      this.close(false);
      this.helper.errorHandler(error)
    });
  }

  createApi(reqData) {
    this.userApi.addUser(reqData).subscribe(value => {
      if (value && value["status"] == "Success") {
        this.success(this.helper.response(value), 'add_new');
      }
    }, error => {
       this.close(false);
       this.helper.errorHandler(error)
    });
  }
  success(res, type) {
    if (type == 'add_new') {
      this.close(true);
    } else if (type == 'update_user') {
      this.close(true);
    } 
  }
}
