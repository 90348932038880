import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AddNewParentComponent } from 'src/app/modules/admin/modals/add-new-parent/add-new-parent.component';
import { CellComp } from 'ag-grid-community';
import { AddNewUserComponent } from 'src/app/modules/admin/modals/add-new-user/add-new-user.component';


@Component({
  selector: 'app-client-role-edit',
  templateUrl: './client-role-edit.component.html',
  styleUrls: ['./client-role-edit.component.css']
})
export class ClientRoleEditComponent implements OnInit {
  data: any;
  textBoxDisabled: boolean;
  isAdd  = true;
  constructor(public router: Router,public dialogs: MatDialog ){}
  agInit(params){
      this.data = params['data'];
     this.isAdd = true;
   }

openDialog(flag) {
  let self = this;
  self.dialogs.open(AddNewParentComponent, {
    width: '620px',
    data: {
      status:flag,
      value: {...this.data}
    }
  });
}
  ngOnInit() {
  }

}
