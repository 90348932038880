import { Injectable, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import jwt_decode from "jwt-decode";
import { USER } from "src/app/constants/const";
import { CookieService } from "ngx-cookie-service";
import { SharedService } from "./shared.service";
import { AdminService } from "src/app/modules/admin/admin.service";
import { ClientService } from "src/app/modules/client/client.service";
import { CoreService } from "./core.service";
import { Router, ActivatedRoute } from '@angular/router';
import { promise } from "protractor";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class LoginService extends CoreService {
  public NODE_URL: string =
    "/api/GetSettings?id=" + eval("parseInt(Math.random() * (10000-1) + 1)");
  private PASSWORD_RESET_ENDPOINT: string;
  private PASSWORD_RESET_ENDPOINT_FINAL: string;
  private PASSWORD_PASS_EXPIRED_ENDPOINT: string;
  private PASSWORD_REGENERATE_ENDPOINT: string;
  public changePasswordFinalResult: string;
  public resetPasswordFinalResult: string;
  private CHECK_PASSWORD_CHANGE_VALID: string;
  private LOGIN_ENDPOINT: string;
  private CLIENT_SECRET: string;
  private CLIENT_ID: string;
  private ENDPOINT_PATH_USER_ROLES: string;
  private ENDPOINT_PATH_USER_TYPE: string;
  public resetPasswordVarifyRes: string = "";
  public env;
  public eventType: string = "";
  constructor(
    public http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private cookieService: CookieService,
    public sharedService: SharedService,
    public adminService: AdminService,
    public clientService: ClientService
  ) {
    super(http);
    let self = this;

    if (
      window.location.host &&
      window.location.host.indexOf("localhost") > -1
    ) {
      this.NODE_URL = "assets/configs.json";
      // this.setConfiguaration();
    } else {
      this.NODE_URL =
        "/api/GetSettings?id=" + eval("parseInt(Math.random() * (10000-1) + 1)");
      // this.setConfiguaration();
    }
    // this.NODE_URL =
    // "/api/GetSettings?id=" + eval("parseInt(Math.random() * (10000-1) + 1)");

    // this.get(this.NODE_URL).subscribe(env => {
    //   if (env) {
    //     this.LOGIN_ENDPOINT = env.EndpointLogin + '/connect/token';
    //     this.PASSWORD_RESET_ENDPOINT = env.EndpointLogin + '/api/sendEmailReset/';
    //     this.PASSWORD_RESET_ENDPOINT_FINAL = env.EndpointLogin + '/api/resetPassword';
    //     this.PASSWORD_REGENERATE_ENDPOINT = env.EndpointLogin + '/api/reGenerateUserPassword';
    //     this.CLIENT_SECRET = env.EndpointCode;
    //     this.CLIENT_ID = env.EndpointEntity;
    //     localStorage.setItem('configData', JSON.stringify(env));
    //   }
    // }, error => {
    //   console.error(error)
    // })
    // this.configAD();
    this.configADlogin().then((response) => {
      debugger;
      var result: any = localStorage.getItem("configData");
      if (result) {
        result = JSON.parse(result);
        this.LOGIN_ENDPOINT = result.EndpointLogin + "/connect/token";
        this.PASSWORD_RESET_ENDPOINT =
          result.EndpointLogin + "/api/sendEmailReset/";
        this.PASSWORD_PASS_EXPIRED_ENDPOINT =
          result.EndpointLogin + "/api/PasswordExpired/{email}/{newPassword}/{x}";
        this.PASSWORD_RESET_ENDPOINT_FINAL =
          result.EndpointLogin + "/api/resetPassword";
        this.PASSWORD_REGENERATE_ENDPOINT =
          result.EndpointLogin + "/api/reGenerateUserPassword";
        this.CHECK_PASSWORD_CHANGE_VALID =
          result.EndpointLogin + "/api/resetPasswordVarify";
        this.CLIENT_SECRET = result.EndpointCode;
        this.CLIENT_ID = result.EndpointEntity;
        debugger;
        localStorage.setItem("configData", JSON.stringify(result));
        this.sharedService.syncConfig();
        this.adminService.syncConfig();
        this.clientService.syncConfig();
      }
    });
  }
  public async configADlogin() {
    debugger;
    var that = this;
    var http = new XMLHttpRequest();
    var url = this.NODE_URL;
    http.open("GET", url, false);
    http.onreadystatechange = function () {
      //Call a function when the state changes.
      if (http.readyState == 4 && http.status == 200) {
        var resultJSON = JSON.stringify(
          JSON.parse(http.responseText.replace(/(\r\n|\n|\r)/gm, ""))
        );
        localStorage.setItem(
          "configData", resultJSON
        );
        that.sharedService.syncConfig();
        that.adminService.syncConfig();
        that.clientService.syncConfig();



        var result = JSON.parse(resultJSON);
        that.LOGIN_ENDPOINT = result.EndpointLogin + "/connect/token";
        that.PASSWORD_RESET_ENDPOINT =
          result.EndpointLogin + "/api/sendEmailReset/";
        that.PASSWORD_PASS_EXPIRED_ENDPOINT =
          result.EndpointLogin + "/api/PasswordExpired/{email}/{newPassword}/{x}";
        that.PASSWORD_RESET_ENDPOINT_FINAL =
          result.EndpointLogin + "/api/resetPassword";
        that.PASSWORD_REGENERATE_ENDPOINT =
          result.EndpointLogin + "/api/reGenerateUserPassword";
        that.CHECK_PASSWORD_CHANGE_VALID =
          result.EndpointLogin + "/api/resetPasswordVarify";
        that.CLIENT_SECRET = result.EndpointCode;
        that.CLIENT_ID = result.EndpointEntity;
        debugger;
        localStorage.setItem("configData", JSON.stringify(result));
        that.sharedService.syncConfig();
        that.adminService.syncConfig();
        that.clientService.syncConfig();

      } else {
      }
    };
    http.send();
  }

  public configAD() {
    let self = this;

    self.sharedService.setConfigApi().subscribe(
      (result) => {
        if (result) {
          // result = JSON.stringify(result)
          this.LOGIN_ENDPOINT = result.EndpointLogin + "/connect/token";
          this.PASSWORD_RESET_ENDPOINT =
            result.EndpointLogin + "/api/sendEmailReset/";
          this.PASSWORD_PASS_EXPIRED_ENDPOINT =
            result.EndpointLogin + "/api/PasswordExpired/{email}/{newPassword}/{x}";
          this.PASSWORD_RESET_ENDPOINT_FINAL =
            result.EndpointLogin + "/api/resetPassword";
          this.PASSWORD_REGENERATE_ENDPOINT =
            result.EndpointLogin + "/api/reGenerateUserPassword";
          this.CLIENT_SECRET = result.EndpointCode;
          this.CLIENT_ID = result.EndpointEntity;
          debugger;
          localStorage.setItem("configData", JSON.stringify(result));
          this.sharedService.syncConfig();
          this.adminService.syncConfig();
          this.clientService.syncConfig();
        }
      },
      (error) => {
        //  console.log(error)
      }
    );
  }

  /**
   * @method: getCompanyInfo
   * @param companyId
   */
  public async login(crendetials: Credentials) {
    var that = this;
    var formBody = [];
    var encodedKey = encodeURIComponent("username");
    var encodedValue = encodeURIComponent(crendetials.username);
    formBody.push(encodedKey + "=" + encodedValue);
    encodedKey = encodeURIComponent("password");
    encodedValue = encodeURIComponent(crendetials.password);
    formBody.push(encodedKey + "=" + encodedValue);
    encodedKey = encodeURIComponent("client_id");
    encodedValue = encodeURIComponent(this.CLIENT_ID);
    formBody.push(encodedKey + "=" + encodedValue);
    encodedKey = encodeURIComponent("client_secret");
    encodedValue = encodeURIComponent(this.CLIENT_SECRET);
    formBody.push(encodedKey + "=" + encodedValue);
    encodedKey = encodeURIComponent("grant_type");
    encodedValue = encodeURIComponent("password");
    formBody.push(encodedKey + "=" + encodedValue);
    encodedKey = encodeURIComponent("scope");
    encodedValue = encodeURIComponent("fs.admin.api.read");
    formBody.push(encodedKey + "=" + encodedValue);
    var formBodyData = formBody.join("&");
    var http = new XMLHttpRequest();
    var url = this.LOGIN_ENDPOINT + "?" + formBodyData;
    var params = formBodyData;
    http.open("POST", url, false);

    //Send the proper header information along with the request
    http.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    const date = new Date();


    var authCookieLifeSpanInSeconds = JSON.parse(
      localStorage.getItem("configData")
    ).AuthCookieLifeSpanInSeconds;

    if (!isNaN(authCookieLifeSpanInSeconds)) {
      authCookieLifeSpanInSeconds = parseInt(authCookieLifeSpanInSeconds);
      date.setTime(date.getTime() + authCookieLifeSpanInSeconds * 1000);
    }
    else {
      date.setTime(date.getTime() + 120 * 60 * 1000);
    }

    http.onreadystatechange = function () {
      //Call a function when the state changes.
      if (http.readyState == 4 && http.status == 200) {
        const decoded: any = jwt_decode(http.responseText);

        that.cookieService.set(USER, http.responseText, {
          expires: date,
          sameSite: "Strict",
          path: "/",
        });
        var logibData: any = {};
        logibData.id = decoded.user_id;
        logibData.email = decoded.email;
        localStorage.setItem("currentUser", JSON.stringify(logibData));
        localStorage.setItem("userKey", decoded.user_id);
        localStorage.setItem("userKey_b2c", decoded.UserIdB2C);
        localStorage.setItem("userType", "1");
        return decoded;
      }
    };
    http.send(params);

    var response = http.response;
    response = JSON.parse(http.response);
    if (response.error != undefined) {
      this.eventType = response.error;
    }
  }

  // public async login(crendetials: Credentials): Promise<any> {
  //   let options = {
  //     headers: new HttpHeaders().set(
  //       "Content-Type",
  //       "application/x-www-form-urlencoded"
  //     ),
  //   };

  //   const body = new URLSearchParams();

  //   body.set("username", crendetials.username);
  //   body.set("password", crendetials.password);
  //   body.set("client_id", this.CLIENT_ID);
  //   body.set("client_secret", this.CLIENT_SECRET);
  //   body.set("grant_type", "password");
  //   body.set("scope", "fs.spc.api.read");
  //   this.postWithOptions(this.LOGIN_ENDPOINT, body.toString(), options)
  //     .toPromise();
  // }
  public async changePasswordFinal(newPassword: string, oldPassword: string) {
    var that = this;
    if (newPassword) {
      var cookieResponce = this.cookieService.get(USER);
      if (!cookieResponce || cookieResponce == '') {
        // window.location.href = "/login";
        if (window.location.pathname != '/login') {
          this.cookieService.deleteAll('/');
          localStorage.clear();
          window.location.href = '/login';
        }
        return;
      }
      const decodedUser: any = jwt_decode(cookieResponce);
      if (decodedUser && decodedUser.user_id && decodedUser.email) {
        var http = new XMLHttpRequest();
        var url =
          this.PASSWORD_REGENERATE_ENDPOINT + "?email=" + decodedUser.email;
        //  var url = this.PASSWORD_RESET_ENDPOINT_FINAL + "?userId=" + userId + "&token=" + token;
        http.open("POST", url, false);

        //Send the proper header information along with the request
        http.setRequestHeader("Content-Type", "application/json");
        http.setRequestHeader("NewPassword", newPassword);
        http.setRequestHeader("OldPassword", oldPassword);
        const date = new Date();
        date.setTime(date.getTime() + 120 * 60 * 1000);
        // var formBody = [];
        // var encodedKey = encodeURIComponent("newPassword");
        // var encodedValue = encodeURIComponent(newPassword);
        // formBody.push(encodedKey + "=" + encodedValue);
        // encodedKey = encodeURIComponent("newPassword");
        // encodedValue = encodeURIComponent(decodedUser.email);
        // formBody.push(encodedKey + "=" + encodedValue);
        // var formBodyData = formBody.join("&");
        // var params = formBodyData;

        http.onreadystatechange = function () {
          //Call a function when the state changes.
          if (http.readyState == 4 && http.status == 200) {
            if (http.responseText == "Password Changed Successfully") {
              that.toastr.success("Password changed Successfully.");
            } else {
              that.toastr.error(http.responseText);
            }
            that.changePasswordFinalResult = http.responseText;
          }
        };
        var bodyObj: any = {};
        bodyObj["newPassword"] = newPassword;
        http.send(bodyObj);
      }
    }
  }
  public async resetPasswordVarify(userId: string, token: string) {
    var that = this;
    this.configADlogin().then((response) => {
      var result: any = localStorage.getItem("configData");
      if (result) {
        result = JSON.parse(result);
        this.CHECK_PASSWORD_CHANGE_VALID =
          result.EndpointLogin + "/api/resetPasswordVarify";
        if (userId && token) {
          var http = new XMLHttpRequest();
          var url =
            this.CHECK_PASSWORD_CHANGE_VALID +
            "?UserId=" +
            userId +
            "&Token=" +
            token;
          //  var url = this.PASSWORD_RESET_ENDPOINT_FINAL + "?userId=" + userId + "&token=" + token;
          http.open("GET", url, false);

          //Send the proper header information along with the request
          // http.setRequestHeader('Content-Type', 'application/json');
          const date = new Date();
          date.setTime(date.getTime() + 120 * 60 * 1000);
          // var formBody = [];
          // var encodedKey = encodeURIComponent("newPassword");
          // var encodedValue = encodeURIComponent(newPassword);
          // formBody.push(encodedKey + "=" + encodedValue);
          // encodedKey = encodeURIComponent("newPassword");
          // encodedValue = encodeURIComponent(decodedUser.email);
          // formBody.push(encodedKey + "=" + encodedValue);
          // var formBodyData = formBody.join("&");
          // var params = formBodyData;

          http.onreadystatechange = function () {
            //Call a function when the state changes.
            if (http.readyState == 4 && http.status == 200) {
              that.resetPasswordVarifyRes = http.responseText;
            }
          };
          http.send();
        }
      }
    });
  }
  public async resetPasswordFinal(
    userId: string,
    token: string,
    newPassword: string
  ) {
    var that = this;
    var http = new XMLHttpRequest();
    var url = this.PASSWORD_RESET_ENDPOINT_FINAL;
    //  var url = this.PASSWORD_RESET_ENDPOINT_FINAL + "?userId=" + userId + "&token=" + token;
    http.open("POST", url, false);

    //Send the proper header information along with the request
    http.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    const date = new Date();
    date.setTime(date.getTime() + 120 * 60 * 1000);
    var formBody = [];
    var encodedKey = encodeURIComponent("newPassword");
    var encodedValue = encodeURIComponent(newPassword);
    formBody.push(encodedKey + "=" + encodedValue);
    var encodedKey = encodeURIComponent("userId");
    var encodedValue = encodeURIComponent(userId);
    formBody.push(encodedKey + "=" + encodedValue);
    var encodedKey = encodeURIComponent("token");
    var encodedValue = encodeURIComponent(token);
    formBody.push(encodedKey + "=" + encodedValue);
    var formBodyData = formBody.join("&");
    var params = formBodyData;

    http.onreadystatechange = function () {
      //Call a function when the state changes.
      if (http.readyState == 4 && http.status == 200) {
        //Call a function when the state changes.
        if (http.responseText == "Password Changed Successfully") {
          that.toastr.success("Password changed Successfully.");
        }
        // else{
        //   that.toastr.error(http.responseText);
        // }
        that.resetPasswordFinalResult = http.responseText;
      } else if (http.status == 400) {
        that.toastr.error("Password reset Link Expired!");
      }
    };
    http.send(params);
  }
  public async passwordExpiredFinal(
    email: string,
    newPassword: string,
    x: string
  ) {
    var that = this;
    var http = new XMLHttpRequest();
    var url = this.PASSWORD_PASS_EXPIRED_ENDPOINT;
    url = url.replace('{email}', encodeURIComponent(email));
    url = url.replace('{x}', encodeURIComponent(x));
    url = url.replace('{newPassword}', encodeURIComponent(newPassword));
    //  var url = this.PASSWORD_RESET_ENDPOINT_FINAL + "?userId=" + userId + "&token=" + token;
    http.open("POST", url, false);

    //Send the proper header information along with the request
    http.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    const date = new Date();
    date.setTime(date.getTime() + 120 * 60 * 1000);
    var formBody = [];
    var encodedKey = encodeURIComponent("email");
    var encodedValue = encodeURIComponent(email);
    formBody.push(encodedKey + "=" + encodedValue);
    var encodedKey = encodeURIComponent("newPassword");
    var encodedValue = encodeURIComponent(newPassword);
    formBody.push(encodedKey + "=" + encodedValue);
    var encodedKey = encodeURIComponent("x");
    var encodedValue = encodeURIComponent(x);
    formBody.push(encodedKey + "=" + encodedValue);
    var formBodyData = formBody.join("&");
    var params = formBodyData;

    http.onreadystatechange = function () {
      //Call a function when the state changes.
      if (http.readyState == 4 && http.status == 200) {
        //Call a function when the state changes.
        if (http.responseText == "Password Changed Successfully") {
          that.toastr.success("Password changed Successfully.");
        }
        // else{
        //   that.toastr.error(http.responseText);
        // }
        that.resetPasswordFinalResult = http.responseText;
      } else if (http.status == 400) {
        that.toastr.error("Password reset Link Expired!");
      }
    };
    http.send(params);
  }
  public async resetPassword(emailId: string) {
    var that = this;
    var http = new XMLHttpRequest();
    var url =
      this.PASSWORD_RESET_ENDPOINT + emailId + "?application=guardian2.api";
    http.open("POST", url, false);

    //Send the proper header information along with the request
    http.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    const date = new Date();
    date.setTime(date.getTime() + 120 * 60 * 1000);

    http.onreadystatechange = function () {
      //Call a function when the state changes.
      if (http.readyState == 4 && http.status == 200) {
        const decoded: any = jwt_decode(http.responseText);

        that.cookieService.set(USER, http.responseText, {
          expires: date,
          sameSite: "Strict",
          path: "/",
        });
      }
    };
    http.send();
  }
  public getUserRole(userId: string): any {
    return this.http
      .get(`${this.ENDPOINT_PATH_USER_ROLES}?UserId=${userId}`)
      .toPromise();
  }
  public getUserType(userId: string): any {
    return this.http
      .get(`${this.ENDPOINT_PATH_USER_TYPE}?UserId=${userId}`)
      .toPromise();
  }
}
type Credentials = {
  username: string;
  password: string;
};
