/* tslint:disable */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotUsernameComponent } from './forgot-username/forgot-username.component';
import { PocComponent } from './poc/poc.component';
import { UserTypeComponent } from './user-type/user-type.component';
import { InfoHelperComponent } from '../shared/pages/info-help/info-help.component';
import { ReleaseNoteComponent } from '../shared/pages/release-note/release-note.component';
import { ResetPassComponent } from './reset-pass/reset-pass.component';
import { PassRestoreComponent } from './pass-restore/pass-restore.component';

const coreRoutes: Routes = [
	{ path: '', redirectTo: '/login' , pathMatch: 'full' },
	{ path: 'login', component: LoginComponent, data: { page: 'login' } },
	{ path: 'user-type', component: UserTypeComponent, data: { page: 'user-type' } },
	//{ path: '', redirectTo: '/forgot-password' , pathMatch: 'full' },
	{ path: 'forgot-password', component: ForgotPasswordComponent, data: { page: 'forgot-password' } },
	{ path: 'pass-restore/:userKey/:x/:message', component: PassRestoreComponent, data: { page: 'pass-restore' } },
	{ path: 'reset-pass/:token/:userId', component: ResetPassComponent, data: { page: 'reset-pass' } },
	{ path: 'forgot-username', component: ForgotUsernameComponent, data: { page: 'forgot-username' } },

	{ path: 'poc', component: PocComponent, data: { page: 'forgot-password' } },
  { path: 'login#state', component: LoginComponent, data: { page: 'login' } },
  { path: 'userguide', component: InfoHelperComponent },
  { path: 'releaseNote', component: ReleaseNoteComponent }

];

@NgModule({
imports: [
 RouterModule.forChild(coreRoutes)
],
exports: [
 RouterModule
]
})
export class CoreRoutingModule { }
