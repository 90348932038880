import { Component, OnInit, OnDestroy, NgModule } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { first } from "rxjs/operators";
import { FormService } from "src/app/services/forms/form.service";
import { USER } from "src/app/constants/const";
import { SharedService } from "src/app/services/shared.service";
import { Helper } from "src/app/shared/classes/helpers.class";
import { Subscription } from "rxjs";
import { Utility } from "src/app/async/utility";
import { ClientLabel } from "../../constants/client.label";
import { Validations } from "../../constants/validations";
import { NgxAdalService } from "ngx-adal-8";
import { CellComp } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { MsalService } from "../../services/msal.service";
import { LoginService } from "../../services/login.service";
import { CookieService } from "ngx-cookie-service";
import jwt_decode from "jwt-decode";
import { User } from "src/assets/lib/msal/lib-commonjs";
import { NgxUiLoaderService } from "ngx-ui-loader";
import * as forge from "node-forge";

@Component({
  templateUrl: "login.component.html",
  styleUrls: ["login.component.css"],
})
//@NgModule({
//    declarations:[LoginComponent]
//})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  constLabel = ClientLabel;
  validMsg = Validations;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = "";
  public loginProgress: boolean = false;
  public invalidCredentials: boolean = false;
  public validationMessage:string='Invalid username or password.';
  helper: Helper = new Helper();
  loginUser: any;
  subscription: Subscription;
  passwordShow = false;
  isFirstAttempt = false;

  publicKey: string = `-----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAskgPKBcNpz71mi4NSYa5
  mazJrO0WZim7T2yy7qPxk2NqQE7OmWWakLJcaeUYnI0kO3yC57vck66RPCjKxWuW
  SGZ7dHXe0bWb5IXjcT4mNdnUIalR+lV8czsoH/wDUvkQdG1SJ+IxzW64WvoaCRZ+
  /4wBF2cSUh9oLwGEXiodUJ9oJXFZVPKGCEjPcBI0vC2ADBRmVQ1sKsZg8zbHN+gu
  U9rPLFzN4YNrCnEsSezVw/W1FKVS8J/Xx4HSSg7AyVwniz8eHi0e3a8VzFg+H09I
  5wK+w39sjDYfAdnJUkr6PjtSbN4/Sg/NMkKB2Ngn8oj7LCfe/7RNqIdiS+dQuSFg
  eQIDAQAB
  -----END PUBLIC KEY-----`;

  //private authService: NgxAdalService
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fs: FormService,
    private shareApi: SharedService,
    private utilService: Utility,
    private toast: ToastrService,
    public msalService: MsalService,
    private cookieService: CookieService,
    public loginService: LoginService,
    public loader: NgxUiLoaderService
  ) {
    this.loginService.configADlogin();
    this.msalService.configAD();
    if (this.msalService.isLoggedIn()) {
      this.router.navigate(["/user-type"]);
    }
    // this.msalService.login();
    this.loginForm = this.fs.createLoginForm();
  }

  ngOnInit() {
    this.loginService.configADlogin();
    this.msalService.configAD();
  }
  login() {
    if(!localStorage.getItem("configData") || localStorage.getItem("configData").trim() == ""){
      window.location.reload();
      return;
    }
    var self = this;
    self.loginProgress = true;
    this.loader.start();
    const form = self.loginForm;
    self.invalidCredentials = false;

    if (!form.valid) {
      form.markAllAsTouched();
      self.loginProgress = false;
      this.loader.stop();
      return;
    }

    self.loginProgress = true;

    var rsa = forge.pki.publicKeyFromPem(this.publicKey);
    var encryptedUserName = window.btoa(rsa.encrypt(form.value["username"]));
    var encryptedPassword = window.btoa(rsa.encrypt(form.value["password"]));
    const payload = {
      //   username: form.value["username"],
      //  password: form.value["password"],
      username: encryptedUserName,
      password: encryptedPassword,
    };

    try {
      self.cookieService.deleteAll("/");
      setTimeout(() => {
        self.loginService
          .login(payload)
          .then((response) => {
            self.loginProgress = false;
            self.validationMessage= self.loginService.eventType;
            if(self.validationMessage.toLowerCase().indexOf("To comply with our security standards, you are required to change your password every".toLowerCase()) != -1){
              this.router.navigate(["/pass-restore/" + encodeURIComponent(encryptedUserName) + "/" + encodeURIComponent(encryptedPassword) + "/" + self.validationMessage]);
            }
            const date = new Date();
            var cookieLifeSpan = JSON.parse(localStorage.getItem("configData")).AuthCookieLifeSpanInSeconds;
            if(!isNaN(cookieLifeSpan)){
              cookieLifeSpan = parseInt(cookieLifeSpan);
              date.setTime(date.getTime() + cookieLifeSpan * 1000);
            }
            else{
              date.setTime(date.getTime() + 120 * 60 * 1000);
            }
            var access_token = self.cookieService.get(USER);
            if (access_token && access_token != "")
            {
              const decoded: any = jwt_decode(access_token);
              localStorage.setItem(
                "token",
                JSON.parse(access_token).access_token
              );
              var user_role = "";
              if (decoded && decoded.user_id) {
                self.router.navigate(["/user-type"]);
              }
              else {
                self.invalidCredentials = true;
              }
            }
            else {
              self.invalidCredentials = true;
            }
          })
          .catch((error) => {
            self.invalidCredentials = true;
            self.toast.error(error.error.message, "Error");
          })
          .finally(() => {
            self.loginProgress = false;
            this.loader.stop();
          });
      }, 200);

      // }, 300);
    } catch (error) {
      self.invalidCredentials = true;
    } finally {
      self.loginProgress = false;
      this.loader.stop();
    }
  }

  /**
   * @method : `login`
   * @purpose : `login authenticated users.`
   */
  //async login() {
  //  //  this.authService.login();
  //    this.utilService.isLoad(true);
  //    const reqObj = await this.helper.formData(this.loginForm);
  //    this.subscription = this.shareApi.login(reqObj).subscribe(async value => {
  //        await this.helper.success(value) ? this.success(value, 'login') : this.errorHandler(value)
  //    }, error => this.errorHandler(error));

  //}

  /**
   * @method : `errorHandler` must be check if any error
   */
  //async errorHandler(err) {
  //    console.error(err)
  //      console.error(err)
  //  }

  //async success(res = [], type = null) {
  //      switch (type) {
  //        case 'login':

  //          if (res && res.hasOwnProperty("status") && res["status"] == "Failed") {
  //            this.utilService.isLoad(false);
  //            this.toast.error("The credentials you supplied were not correct or did not grant access to this resource", "User Authentication!!");
  //          } else {
  //              this.loginUser = await this.helper.response(res);
  //              this.userRedirect(this.loginUser);
  //              this.toast.success("You are login successfully.", "User Authentication!!");
  //          }
  //          break;
  //          default:
  //              break;
  //      }
  //  }

  /**
   * @method : `userRedirect`
   * @purpose : `this is checked redirect. as based on user role`
   */
  //userRedirect(loginUser): void {
  //    this.utilService.isLoad(true);
  //  if (loginUser.hasOwnProperty('userType') && loginUser.userType == 'client') {
  //        localStorage.setItem('currentUser', JSON.stringify(loginUser));
  //        this.router.navigate(['/client/orders']);
  //  } else if (loginUser.hasOwnProperty('userType') && loginUser.user_type == 'admin') {
  //        localStorage.setItem('currentUser', JSON.stringify(loginUser))
  //        this.router.navigate(['/admin/']);
  //    } else {
  //        this.utilService.isLoad(false);
  //        localStorage.clear();
  //    }
  //}

  ngOnDestroy() {
    this.loginForm = null;
    this.loginUser = null;
    this.helper = null;
    // this.utilService.isLoad(false);
    //this.subscription.unsubscribe();
  }

  showPassword() {
    this.passwordShow = !this.passwordShow;
  }
}
