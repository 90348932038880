<h2 mat-dialog-title>Create new user</h2>
<form [formGroup]="addNewUser" novalidate (ngSubmit)="add()">
  <mat-dialog-content class="mat-typography">

    <div class="row">
      <div class="col-lg-6">
        <label class="required-field">First_Name:</label>
        <input matInput placeholder="" type="text" formControlName="firstName"
          [value]="(stateList$ | async)?.addNewUser?.firstname" autocomplete="off">
        <mat-error class="error"
          *ngIf="addNewUser?.controls['firstName']?.invalid && (addNewUser.controls['firstName']?.dirty || addNewUser.controls['firstName']?.touched)">
          <div *ngIf="f.firstName.errors">
            <div *ngIf="f.firstName.errors.required">
              {{validMsg.Message.firstNameRequired}}
            </div>
          </div>
        </mat-error>
      </div>
      <div class="col-lg-6">
        <label class="required-field">Last_Name:</label>
        <input matInput placeholder="" type="text" formControlName="lastName">
        <mat-error class="error"
          *ngIf="addNewUser?.controls['lastName']?.invalid && (addNewUser.controls['lastName']?.dirty || addNewUser.controls['lastName']?.touched)">
          <div *ngIf="f.lastName.errors">
            <div *ngIf="f.lastName.errors.required">
              {{validMsg.Message.lastNameRequired}}
            </div>
          </div>
        </mat-error>
      </div>
      <div class="col-lg-6">
        <label class="required-field"> Email:</label>
        <input matInput placeholder="" type="email" formControlName="email"
          pattern="[a-zA-z0-9]{1,}@{1}[A-Za-z]{2,10}[.]{1}[^(web)|^(\s)]{2,5}">
        <mat-error class="error"
          *ngIf="addNewUser?.controls['email']?.invalid && (addNewUser.controls['email']?.dirty || addNewUser.controls['email']?.touched)">
          <div *ngIf="f.email.errors">
            <div *ngIf="f.email.errors.required">
              {{validMsg.Message.emailFieldRequird}}
            </div>
          </div>
        </mat-error>
      </div>
      <div class="col-lg-6">
        <label class="">Phone:</label>
        <input matInput placeholder="" type="tel" formControlName="phone">
      </div>
      <div class="col-lg-6">
        <label class="required-field">Mobile</label>
        <input matInput placeholder="" type="text" formControlName="mobile">
        <mat-error class="error"
          *ngIf="addNewUser?.controls['mobile']?.invalid && (addNewUser.controls['mobile']?.dirty || addNewUser.controls['mobile']?.touched)">
          <div *ngIf="f.mobile.errors">
            <div *ngIf="f.mobile.errors.required">
              {{validMsg.Message.contactFieldRequired}}
            </div>
          </div>
        </mat-error>
      </div>
      <div class="col-lg-6">
        <label class="">Address:</label>
        <input matInput placeholder="" type="text" formControlName="address">
        <mat-error class="error"
          *ngIf="addNewUser?.controls['address']?.invalid && (addNewUser.controls['address']?.dirty || addNewUser.controls['address']?.touched)">
          <div *ngIf="f.address.errors">
            <div *ngIf="f.address.errors.required">
              {{validMsg.Message.propertyAddressRequired}}
            </div>
          </div>
        </mat-error>
      </div>
      <div class="col-lg-6">
        <label class="">Address1:</label>
        <input matInput placeholder="" type="text" formControlName="address">
      </div>
      <div class="col-lg-6">
        <label class="">City:</label>
        <input matInput placeholder="" type="text" formControlName="city">
      </div>
      <div class="col-lg-6">
        <label class="required-field">State:</label>
        <!--<mat-select formControlName="state">

          <mat-option value="">-- Select --</mat-option>
          <mat-option *ngFor="let st of (stateList$ | async)" [value]="st['stateCode']">{{st['stateName']}}</mat-option>
          <mat-error class="error"
            *ngIf="addNewUser?.controls['state']?.invalid && (addNewUser.controls['state']?.dirty || addNewUser.controls['state']?.touched)">
            <div *ngIf="f.state.errors">
              <div *ngIf="f.state.errors.required">
               
              </div>
            </div>
          </mat-error>
        </mat-select>-->
      </div>
      <div class="col-lg-6">
        <label class="required-field">Role:</label>
        <mat-select formControlName="userrole">
          <mat-option value="">-- Select --</mat-option>
          <mat-option *ngFor="let rl of (roleArray$ | async)" [value]="rl['roleName']">{{rl['roleName']}}
          </mat-option>
          <mat-error class="error"
            *ngIf="addNewUser?.controls['userrole']?.invalid && (addNewUser.controls['userrole']?.dirty || addNewUser.controls['userrole']?.touched)">
            <div *ngIf="f.userrole.errors">
              <div *ngIf="f.userrole.errors.required">
                {{validMsg.Message.userRoleRequired}}
              </div>
            </div>
          </mat-error>
        </mat-select>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="popup-add-btn">
          <button mat-flat-button color="primary" class="fs-button"
            [disabled]="addNewUser.invalid">{{(data) ? 'Update' : 'Add'}}</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</form>
<button mat-button mat-dialog-close class="popup-close-btn"><i class="material-icons">close</i></button>
