import { Injectable, OnInit } from '@angular/core';
import { CoreService } from './core.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { Observable, of } from 'rxjs';
import { User } from '../shared/interface/users.model';


@Injectable({
  providedIn: 'root'
})
export class SharedService extends CoreService implements OnInit {

  public API_ENDPOINT;
  public config$: Observable<any>;


  public NODE_URL: string =
    "/api/GetSettings?id=" + eval("parseInt(Math.random() * (10000-1) + 1)");
  // public NODE_URL: string = "/currentConfigs.js?id=" + eval('parseInt(Math.random() * (10000-1) + 1)');


  constructor(public http: HttpClient) {
    super(http);
    let self = this;

    if (window.location.host && window.location.host.indexOf('localhost') > -1) {
      this.NODE_URL = "assets/configs.json";
     // this.setConfiguaration();
    } else {
      this.NODE_URL = "/api/GetSettings?id=" + eval("parseInt(Math.random() * (10000-1) + 1)");
     // this.setConfiguaration();
    }
    // this.NODE_URL = "/api/GetSettings?id=" + eval("parseInt(Math.random() * (10000-1) + 1)");

    if (localStorage.getItem("configData")) {
      let env = JSON.parse(localStorage.getItem("configData"));
      self.API_ENDPOINT = env.API_ENDPOINT;
    }
  }
  syncConfig(){
    let self = this;

     if (window.location.host && window.location.host.indexOf('localhost') > -1) {
       this.NODE_URL = "assets/configs.json";
      // this.setConfiguaration();
     } else {
       this.NODE_URL = "/api/GetSettings?id=" + eval("parseInt(Math.random() * (10000-1) + 1)");
      // this.setConfiguaration();
     }

    if (localStorage.getItem("configData")) {
      let env = JSON.parse(localStorage.getItem("configData"));
      self.API_ENDPOINT = env.API_ENDPOINT;
    }
  }

  ngOnInit() {
    let self = this;
    if (localStorage.getItem("configData")) {
      let env = JSON.parse(localStorage.getItem("configData"));
      self.API_ENDPOINT = env.API_ENDPOINT;
    }
  }



  setConfigApi(): Observable<any> {
      if ((window.location.host && window.location.host.indexOf('localhost') > -1 ) || window.location.host.indexOf('192.168.0.30') > -1) {
              let host = 'localhost';
              if(window.location.host.indexOf('192.168.0.30') > -1){
                host = '192.168.0.30';
              }
            return of({
              "timestamp": "2020-02-17T07:01:07.365Z",
              "API_ENDPOINT": `https://${host}:44357/`,
              "B2C_SCOPE": "[\"openid\", \"profile\"]",
              "CLIENT_KEY": "4cbb14bf-91f6-4eef-90a7-d5e27ad6bfdc",
              "MICROSOFT_LOGIN_URL": "https://login.microsoftonline.com/tfp/",
              "MSAL_REDIRECT_URL": `http://${host}:44316/user-type`,
              "RESET_PWD_POLICY": "B2C_1_PasswordReset",
              "SIGNIN_POLICY": "B2C_1_SignUpSignIn",
              "SIGNUP_POLICY": "B2C_1_SignIn",
              "EDIT_POLICY": "B2C_1_EditProfileDD",
              "TENANT_KEY": "aaf09b0a-e24c-4359-b3d4-7553a65a722d",
              "EndpointCode": "dcfa18b2-c330-4d5f-bdba-ea8ecc3302b6",
              "EndpointLogin": "https://localhost:5000",
              "EndpointEntity": "fs.admin.ui",
              "AG_GRID_KEY": "CompanyName=FundingShield LLC,LicensedApplication=Guardian,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-012692,ExpiryDate=21_January_2022_[v2]_MTY0MjcyMzIwMDAwMA==d2cb4beea513d51fb197ddcf1410c40b",
              "LoggedInIdleTimeout":30,
              "PasswordExpiryWarningDaysLeft": "7",
              "AuthCookieLifeSpanInSeconds":120
            });
      }
      else{
         return this.get(this.NODE_URL);
      }

  }
  setConfiguaration() {
       this.get(this.NODE_URL).subscribe(value => {
      if (value) {
        this.config$ = of(value);
        debugger;
        localStorage.setItem("configData", JSON.stringify(value));
        this.API_ENDPOINT = value["API_ENDPOINT"];



        console.log(this.API_ENDPOINT)
      }
    }, error => {
      console.error(error)
    })
  }

   /**
    * @method: login
    * @param reqData
    */
   public login(reqData: object): Observable<any> {
     return this.post(`${this.API_ENDPOINT}/Login`, reqData);
   }


   public isExists(url, reqObj): Observable<any> {
    return ;
   }


   public  isLogedin(): Observable<any>{
    return localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : undefined;

   }

   /**
    * @method `Forget Username`
    */
   public  forgetUsername(reqObj): Observable<any>{
    return this.post(`forget-username`, reqObj);
   }

   /**
    * @method `Forget Password`
    */
   public  forgetPwd(reqObj): Observable<any>{
    return this.post(`forget-username`, reqObj);
   }


   /**
    * @method: getState
    * @return `List of State`
    */
  getState(): Observable<any> {
    let baseUrl;
    if (localStorage.getItem('configData')) {
      let configData = JSON.parse(localStorage.getItem('configData'));
      baseUrl = configData['API_ENDPOINT'];
    }




    if (this.API_ENDPOINT) {
      return this.get(this.API_ENDPOINT + `DataLoad/GetStateList?clientKey=1234`)
    }
    else {


      return this.get(this.API_ENDPOINT || baseUrl + `DataLoad/GetStateList?clientKey=1234`)

    }

  }


   /**
    * @method: getProfile
    */
   getProfile(reqObj): Observable<any> {
     //return this.get(this.API_ENDPOINT + `User/GetUser?loggedInUserId=${reqObj['userKey']}&azureUserId=${reqObj['azureUserKey']}`)



     let baseUrl;
     if (localStorage.getItem('configData')) {
       let configData = JSON.parse(localStorage.getItem('configData'));
       baseUrl = configData['API_ENDPOINT'];
     }
     if (this.API_ENDPOINT) {
       return this.get(this.API_ENDPOINT + `User/GetUser?loggedInUserId=${reqObj['userKey']}&azureUserId=${reqObj['azureUserKey']}`)
     }
     else {
       return this.get(this.API_ENDPOINT || baseUrl + `User/GetUser?loggedInUserId=${reqObj['userKey']}&azureUserId=${reqObj['azureUserKey']}`)
     }
  }
  /**
 * @method: getClientAdminList
 */
  getClientAdminList(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `Transaction/getClientAdminList?loggedInUserId=${reqObj['userKey']}`)
  }
   /**
    * @method : `updateProfile`
    * @purpose : for this `required for update profile.`
    */
  updateProfile(userKey,azureUserKey,reqObj): Observable<any>{
    return this.patch(this.API_ENDPOINT + `User/ProfileUpdate?loggedInUserId=` + userKey + `&azureUserId=` + azureUserKey, reqObj);
   }
  /**
 * @method : `updateProfile`
 * @purpose : for this `required for update profile.`
 */
  passwordChange(userKey, azureUserKey, reqObj): Observable<any> {
    return this.patch(this.API_ENDPOINT + `User/PasswordChange?loggedInUserId=` + userKey + `&azureUserId=` + azureUserKey, reqObj);
  }
   /**
    * @method : `changePasword`
    * @purpose : for this `required for change password.`
    */
   changePasword(reqObj, user_id, client_id): Observable<any>{
    return this.post(`change-password?user_id=1112d&client_id=4545`, reqObj);
   }


   /**
    * @method : getOrders
    * @required : this method get the data based on order id
     */
  getUserDetails(reqObj) {
    let baseUrl;
    if (localStorage.getItem('configData')) {
      let configData = JSON.parse(localStorage.getItem('configData'));
      baseUrl = configData['API_ENDPOINT'];
      this.API_ENDPOINT = configData['API_ENDPOINT'];
    }
    if (this.API_ENDPOINT) {
      return this.get(this.API_ENDPOINT + `User/GetUserDetail?userKey=${reqObj['userKey']}`)
    }
    else
    {
      return this.get(this.API_ENDPOINT || baseUrl + `User/GetUserDetail?userKey=${reqObj['userKey']}`)
      //let a = this.API_ENDPOINT || baseUrl + `User/GetUserDetail?userKey=${reqObj['userKey']}`;
      //setTimeout(() => {
      //  return this.get(this.API_ENDPOINT || baseUrl + `User/GetUserDetail?userKey=${reqObj['userKey']}`)
      //}, 2000)
    }


  }


  clientSearch(reqObj = {}): Observable<any> {

    let baseUrl;
    if (localStorage.getItem('configData')) {
      let configData = JSON.parse(localStorage.getItem('configData'));
      baseUrl = configData['API_ENDPOINT'];
      this.API_ENDPOINT = configData['API_ENDPOINT'];
    }
    if (this.API_ENDPOINT) {

      return this.get(this.API_ENDPOINT + `DataLoad/SearchClientByStateCodeAndUserId?clientKey=${reqObj['clientKey']}&userKey=${reqObj['userKey']}&stateCode=${reqObj['state']}`)
    }
    else {

      return this.get(this.API_ENDPOINT + `DataLoad/SearchClientByStateCodeAndUserId?clientKey=${reqObj['clientKey']}&userKey=${reqObj['userKey']}&stateCode=${reqObj['state']}`)
    }


  }





   /**
   * @method : getOrders
   * @required : this method get the data based on order id
    */
  getOrder(reqObj) {

    let baseUrl;
    if (localStorage.getItem('configData')) {
      let configData = JSON.parse(localStorage.getItem('configData'));
      baseUrl = configData['API_ENDPOINT'];
      this.API_ENDPOINT = configData['API_ENDPOINT'];
    }

    if (this.API_ENDPOINT) {
      return this.get(this.API_ENDPOINT + `Transaction/getOrderDetailsByOrderId?orderKey=${reqObj['orderKey']}&userKey=${reqObj['userKey']}&clientKey=${reqObj['clientKey']}&userType=1`)
    }
    else {
      return this.get(this.API_ENDPOINT || baseUrl + `Transaction/getOrderDetailsByOrderId?orderKey=${reqObj['orderKey']}&userKey=${reqObj['userKey']}&clientKey=${reqObj['clientKey']}&userType=1`)

    }
  }
    /**
    * @method : getOrderDetails
    * @required : this method get the data based on order id
     */
    getOrderDetails(reqObj) {

      return this.get(`assets/api/admin_order_details.json`)
    }

    getOrderDetailsByAdmin(reqObj): Observable<any> {
      return this.get(this.API_ENDPOINT + `AdminTransaction/getOrderDetailsByOrderId?userKey=${reqObj['userKey']}&userType=${reqObj['userType']}&orderKey=${reqObj['orderKey']}&clientKey=${reqObj['clientKey']}`);
    }
   /**
    * @method : getClientList
    * @required : @this method Get Client List.
    */
    getClients(reqObj) {
      return this.get(`assets/api/client-list.json`)
    }


  //resetPassword(reqObj): Observable<any> {
  //  return this.get(this.API_ENDPOINT + `User/ResetPassword?loggedInUserId=` + reqObj['loggedInUserId'] + `&azureUserId=` + reqObj['azureUserId'] + `&password=` + reqObj['password']);
  //}


  resetPassword(reqObj): Observable<any> {
    return this.post(this.API_ENDPOINT + `User/ResetPassword`, reqObj);

  }

  getCompanyList(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `DataLoad/GetParentChildCompanyTree?userKey=${reqObj['userKey']}&clientKey=${reqObj['clientKey']}`);
  }


  updateProfilePic(reqData): Observable<any> {
    return this.post(this.API_ENDPOINT + `User/ProfilePicUpload?loggedInUserId=` + reqData['loggedInUserId'] + `&azureUserId=` + reqData['azureUserId'], reqData);
  }

  ProfileUpdateExtension(loggedInUserId, azureUserId, reqData): Observable<any> {
    return this.patch(this.API_ENDPOINT + `UserUpdateExtension?loggedInUserId=` + loggedInUserId + `&azureUserId=` + azureUserId, reqData);
  }

  ProfileAddExtension(loggedInUserId, azureUserId, reqData): Observable<any> {
    return this.patch(this.API_ENDPOINT + `AddExtension?loggedInUserId=` + loggedInUserId + `&azureUserId=` + azureUserId, reqData);
  }

  GetReleaseNote(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `ReleaseNotes/GetReleaseNote?LoggedInUser=` + reqObj['LoggedInUser']);
  }

  GetUserGuide(reqObj): Observable<any> {
    return this.get(this.API_ENDPOINT + `ReleaseNotes/GetUserGuideDocs?LoggedInUser=${reqObj['LoggedInUser']}&Project=${reqObj['Project']}`);
  }
}
