import { createSelector } from '@ngrx/store';
import { IAppState } from '../state/admin/app.state';
import { StateListState } from '../state/admin/admin.state';
const selectOrderDetails = (state: IAppState) => state.orderDetails;
const selectClientList = (state: IAppState) => state.clientList;
const states = (state: IAppState) => state.stateList;
export const getOrderDetails = createSelector(
   selectOrderDetails,
   (state: any) => state.orderDetails
 );
 

 export const getClientList = createSelector(
  selectClientList,
  (state: any) => state.clientList
);


export const stateList = createSelector(
  states,
  (state: StateListState) => state.stateList
);