<h2 mat-dialog-title>Add New Parent Company</h2>
<form [formGroup]="addNewParent" novalidate (ngSubmit)="add()">
  <mat-dialog-content class="mat-typography" class="add-new-parent-popup">
    <div class="row">
      <mat-radio-group aria-label="Select an option">
        <mat-radio-button value="1" [checked]="!isParent" [disabled]="isParent">ParentCompany </mat-radio-button>
        <mat-radio-button value="2" [checked]="isParent" [disabled]="!isParent">ChildCompany</mat-radio-button>
      </mat-radio-group>
      <!-- <div class="col-lg-12">

    <div class="col-lg-12">
  <mat-radio-group aria-label="Select an option" class="fa-radio">
    <label class="radio-title">Select Company</label>
    <mat-radio-button  value="1"  checked [disabled]="isParent" (click)="isDisabled(1, 'parent')" >ParentCompany </mat-radio-button>
    <mat-radio-button value="2"   [disabled]="!isParent" (click)="!isParent && isDisabled(2, 'child')">ChildCompany</mat-radio-button>
  </mat-radio-group>
    </div>
  <div class="col-lg-12">
    <label>Parent Company Title:</label>
    <input matInput placeholder="" type="text" [disabled]="isParent">
  </div> -->
      <div class="col-lg-12">
        <label class="required-field">Company Name:</label>
        <input matInput placeholder="" type="text" formControlName="companyName">
        <!-- <mat-error class="error"
      *ngIf="addNewParent?.controls['parentCompany']?.invalid && (addNewParent.controls['parentCompany']?.dirty || addNewParent.controls['parentCompany']?.touched)">
      <div *ngIf="f.parentCompany.errors">
        <div *ngIf="f.parentCompany.errors.required">
          {{validMsg.Message.parentCompanyRequired}}
        </div>
      </div>
    </mat-error> -->
      </div>
      <!-- <div class="col-lg-12">
    <label>Child Company Title:</label>
    <input matInput placeholder=""  [disabled]="!isParent"type="text" >
  </div> -->
      <div class="col-lg-12">
        <label class="required-field">Email:</label>
        <input matInput placeholder="" type="text" formControlName="Email">
        <!-- <mat-error class="error"
      *ngIf="addNewParent?.controls['childCompany']?.invalid && (addNewParent.controls['childCompany']?.dirty || addNewParent.controls['childCompany']?.touched)">
      <div *ngIf="f.childCompany.errors">
        <div *ngIf="f.childCompany.errors.required">
          {{validMsg.Message.childCompanyRequired}}
        </div>
      </div>
    </mat-error> -->
      </div>
      <div class="col-lg-12">
        <label class="">Phone:</label>
        <input matInput placeholder="" type="text" formControlName="phone">
        <!-- <mat-error class="error"
      *ngIf="addNewParent?.controls['childCompany']?.invalid && (addNewParent.controls['childCompany']?.dirty || addNewParent.controls['childCompany']?.touched)">
      <div *ngIf="f.childCompany.errors">
        <div *ngIf="f.childCompany.errors.required">
          {{validMsg.Message.childCompanyRequired}}
        </div>
      </div>
    </mat-error> -->
      </div>
      <div class="col-lg-12">
        <label class="required-fiel">Mobile:</label>
        <input matInput placeholder="" type="text" formControlName="mobile">
        <!-- <mat-error class="error"
      *ngIf="addNewParent?.controls['childCompany']?.invalid && (addNewParent.controls['childCompany']?.dirty || addNewParent.controls['childCompany']?.touched)">
      <div *ngIf="f.childCompany.errors">
        <div *ngIf="f.childCompany.errors.required">
          {{validMsg.Message.childCompanyRequired}}
        </div>
      </div>
    </mat-error> -->
      </div>
      <div class="col-lg-12">
        <label class="">Address:</label>
        <input matInput placeholder="" type="text" formControlName="address">
        <!-- <mat-error class="error"
      *ngIf="addNewParent?.controls['childCompany']?.invalid && (addNewParent.controls['childCompany']?.dirty || addNewParent.controls['childCompany']?.touched)">
      <div *ngIf="f.childCompany.errors">
        <div *ngIf="f.childCompany.errors.required">
          {{validMsg.Message.childCompanyRequired}}
        </div>
      </div>
    </mat-error> -->
      </div>
      <div class="col-lg-12">
        <label class="">Address2:</label>
        <input matInput placeholder="" type="text" formControlName="address">
        <!-- <mat-error class="error"
      *ngIf="addNewParent?.controls['childCompany']?.invalid && (addNewParent.controls['childCompany']?.dirty || addNewParent.controls['childCompany']?.touched)">
      <div *ngIf="f.childCompany.errors">
        <div *ngIf="f.childCompany.errors.required">
          {{validMsg.Message.childCompanyRequired}}
        </div>
      </div>
    </mat-error> -->
      </div>
      <div class="col-lg-12">
        <label class="">City:</label>
        <input matInput placeholder="" type="text" formControlName="city">
      </div>
      <div class="col-lg-12">
        <label class="required-field">State:</label>
        <input matInput placeholder="" type="text" formControlName="state">
         <!--<mat-error class="error"
      *ngIf="addNewParent?.controls['childCompany']?.invalid && (addNewParent.controls['childCompany']?.dirty || addNewParent.controls['childCompany']?.touched)">
      <div *ngIf="f.childCompany.errors">
        <div *ngIf="f.childCompany.errors.required">
          {{validMsg.Message.staterequired}}
        </div>
      </div>
    </mat-error>--> 
      </div>
      <div class="col-lg-12">
        <label class="">Zip:</label>
        <input matInput placeholder="" type="text" formControlName="zip">
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="popup-add-btn">
          <button mat-flat-button color="primary" class="fs-button"
            [disabled]="addNewParent.invalid">{{(!isEdit) ? 'Add' : 'Edit'}}</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</form>
<button mat-button mat-dialog-close class="popup-close-btn"><i class="material-icons">close</i></button>
