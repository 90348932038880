import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utility } from 'src/app/async/utility';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { LoanNumberComponent } from 'src/app/modules/client/orders/models/loan-number/loan-number.component';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {
  static formatForDisplay(pars: any) {
    throw new Error("Method not implemented.");
  }

  constructor(public router: Router,
    private utilService: Utility,
    public route: ActivatedRoute,
   public dialog: MatDialog) { }
  id: any;
  loanNumber: any;
  ngOnInit() {
  }

  agInit(params) {
    if (params && params.hasOwnProperty('data') && params['data'].hasOwnProperty('transactionKey')) {
      this.loanNumber = params['data']['loanNumber'];
      this.id = params['data']['transactionKey'];

    } 
  }
 
  editBtn(id){
   // alert(id)
    this.utilService.isLoad(true)
    if (this.router.url.indexOf('client') > -1) {
      this.router.navigate(['/client/orders/edit-transaction/' + id])
    }
    else if (this.router.url.indexOf('admin') > -1) {
      this.router.navigate(['admin/order-details/' + id])
    }
   
  }

  createDuplicateOrder(id) {
    let self = this;
    //let transId = this.route.snapshot.paramMap.get('id');
    let dialogRef = self.dialog.open(LoanNumberComponent, {
      width: '500px', maxHeight: '210px', disableClose: true, data: { loan_number: this.loanNumber, id: id }
    });

    dialogRef.afterClosed().pipe(
      filter(res => res)
    ).subscribe(res => {
      if (res) { }
    });
  }
}

