import { NgModule } from '@angular/core';
import { HeaderComponent } from './pages/header/header.component';
import { CommonModule } from '@angular/common';
import { SharedMaterialModule } from './material/material.module';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { RouterModule } from '@angular/router';
import { AddCompanyHierarchyComponent } from './pages/add-company-hierarchy/add-company-hierarchy.component';
import { AgGridMyModule } from 'src/app/shared/grid/ag-grid.module';
import { TreeviewModule } from 'ngx-treeview';
import { InfoHelperComponent } from './pages/info-help/info-help.component';
import { ReleaseNoteComponent } from './pages/release-note/release-note.component';
import { ToolTipComponent } from './pages/tool-tip/tool-tip.component';
import { ClosingAgentContactComponent } from './pages/closing-agent-contact/closing-agent-contact.component';

@NgModule({
  declarations: [HeaderComponent, PrivacyPolicyComponent, DisclaimerComponent, ProfileComponent, AddCompanyHierarchyComponent, InfoHelperComponent, ReleaseNoteComponent, ToolTipComponent, ClosingAgentContactComponent],
  imports: [
   
    CommonModule,
    SharedMaterialModule,
    NgxMaskModule.forRoot(),
    TreeviewModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    AgGridMyModule
    //ToastrModule.forRoot({
    //  timeOut: 10000,
    //  positionClass: 'toast-top-right'
    //}),
    
  ],
  exports: [
    HeaderComponent,
    PrivacyPolicyComponent,
    DisclaimerComponent,
    ProfileComponent,
    
  ],

  entryComponents: [InfoHelperComponent, ReleaseNoteComponent, ToolTipComponent, ClosingAgentContactComponent]
})
export class SharedModule { }
