import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { Utility } from 'src/app/async/utility';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { ClientService } from '../../../client.service';
import { IAppState } from 'src/app/store/state/admin/app.state';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Orders, OrderActions, LoanNumber } from 'src/app/store/actions/order.actions';

@Component({
  selector: 'app-loan-number',
  templateUrl: './loan-number.component.html',
  styleUrls: ['./loan-number.component.css']
})
export class LoanNumberComponent implements OnInit {
  loanExists = false;
  loanNumberSubmitted = false;
  loanNumber: any;
  loanId: any;
  isSpeener$: Observable<boolean>;
  userKey;

  constructor(public lrApi: ClientService,
    public router: Router,
    private store: Store<IAppState>,
    private utilService: Utility,
    public loader: NgxUiLoaderService,
    private dialogRef: MatDialogRef<LoanNumberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.loanNumber = data.loan_number + '-1';
    this.loanId = data.id;
  }

  ngOnInit() {
    if (this.utilService) {
      this.userKey = this.utilService.getUserId();
    }
  }

  createOrder() {
    //API to check loan existing or not
    this.isSpeener$ = of(true);
    let reqObj = { };
    reqObj['userKey'] = this.userKey;
    reqObj['clientKey'] = "12345";
    reqObj['loan_number'] = this.loanNumber;
    this.loanNumberSubmitted = true;

    if (this.loanNumber.trim().length < 1) {
      this.isSpeener$ = of(false);
      this.loanExists = false;
      return false;
    }



    this.lrApi.isLoanExists(reqObj).subscribe(response => {
      if (response && response['value']) {
        localStorage.removeItem('loanNumber');
        this.loanExists = true;
        this.isSpeener$ = of(false);
      } else {
        this.store.dispatch(new LoanNumber({ loanNumber: reqObj['loan_number'] }));
        localStorage.setItem('loanNumber', reqObj['loan_number']);
        this.isSpeener$ = of(false);
        this.loanExists = false;
        this.success(response, 'loan_is_exists')
      }
    }, error => {
      console.error(error);
    });
  }

  success(res, type): void {
    let self = this;
    switch (type) {
      case 'loan_is_exists':
        if (res && res['value']) {
          self.loanExists = true;
        } else {
          self.loanExists = false;
          this.dialogRef.close();
          this.store.dispatch(new LoanNumber({ loanNumber: self.loanNumber }));
          //Query params

          // // // this.router.navigate(['/client/orders/edit-transaction/' + this.loanId], { queryParams: { isDuplicate: true } });

          this.loader.start();
          this.lrApi.duplicateOrder(self.loanId, self.userKey, self.loanNumber).subscribe(response => {  //response.value

            this.loader.stop();
            if(response.value && response.value != null){
              this.router.navigate(['/client/orders/edit-transaction/' + response.value]);
            }
          });
        }
      default:
        break;
    }
  }


}
