<div class="container-fluid profiles-bg">
        <div class="row profile-row">
                <div class="left-profile">
                        <div class="image-container"><img  [src]="img$ | async"> </div>
                       <div class="name">{{userName$ | async}}</div>
                       <div class="designation">{{roleType$ | async}}</div>
                </div>
                <div class="col-lg-12 tab-container-bg">
                        <div class="right-profile">

                                <mat-tab-group class="horizontal-tab" [(selectedIndex)]="selectedIndex">

                                        <mat-tab label="Update Profile">
                                                <form [formGroup]="profileForm" novalidate (ngSubmit)="updateProfile()">
                                                  <div class="row ag-grid-profile">
                                                    <div class="col-lg-6">
                                                      <div class="row">
                                                        <div class="col-lg-12 fs-filetype">
                                                          <label>Profile Picture:</label>
                                                          <div class="file-container">
                                                            <mat-form-field appearance="outline">
                                                              <ngx-mat-file-input placeholder="Basic Input" (change)="fileUpload($event)" (cancel)="cancel()" formControlName="profileImage">
                                                              </ngx-mat-file-input>
                                                              <span class="file-btn">Choose File</span>
                                                            </mat-form-field>
                                                            <mat-error *ngIf="changePasswordForm.controls['profileImage']?.errors?.required">
                                                              Please select a file
                                                            </mat-error>
                                                            <mat-error *ngIf="changePasswordForm.controls['profileImage']?.errors?.maxContentSize">
                                                              The total size must not exceed {{changePasswordForm.controls['profileImage']?.errors?.maxContentSize.maxSize | byteFormat}} ({{
changePasswordForm.controls['profileImage']?.errors?.maxContentSize.actualSize
                                                                                                | byteFormat
                                                              }}).
                                                            </mat-error>

                                                          </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                          <label class="required-field">First Name:</label>
                                                          <input matInput placeholder="" type="text" formControlName="displayName">
                                                          <mat-error class="cus-error" *ngIf="profileForm.get('displayName')?.touched && profileForm.get('displayName').errors?.required">
                                                            <span>
                                                              First Name is required.
                                                            </span>
                                                          </mat-error>
                                                        </div>
                                                        <div class="col-lg-12">
                                                          <label>Last Name:</label>
                                                          <input matInput placeholder="" type="text" formControlName="surname">
                                                        </div>
                                                        <div class="col-lg-12">
                                                          <label class="required-field">Email:</label>
                                                          <input class="disable" matInput placeholder="" type="email" formControlName="userPrincipalName" readonly>
                                                        </div>
                                                        <div class="col-lg-12">
                                                          <label>Title:</label>
                                                          <input matInput placeholder="" type="text" formControlName="jobTitle">
                                                        </div>
                                                        <div class="col-lg-12">
                                                          <div class="phone-bg">
                                                            <label>Phone:</label>
                                                            <input matInput placeholder="" (keyup)="removeMaskFormat($event,'businessPhones')" type="tel"
                                                                   mask="(000) 000-0000" formControlName="businessPhones">

                                                            <mat-error class="cus-error"  *ngIf="profileForm.get('businessPhones')?.touched && profileForm.get('businessPhones').errors?.minlength">
                                                              <span >
                                                                Phone must have 10 digits.
                                                              </span>

                                                            </mat-error>


                                                          </div>

                                                          <div class="extension-bg">
                                                            <label>EXT:</label>
                                                            <input matInput placeholder="" minlength="3" (keyup)="removeMaskFormat($event,'extension')" type="tel"
                                                                   mask="(000)" formControlName="extension">
                                                            <mat-error class="cus-error with-ext" *ngIf="profileForm.get('extension')?.touched && profileForm.get('extension').errors?.minlength">
                                                              <span>
                                                                Extension must have 3 digits.
                                                              </span>

                                                            </mat-error>
                                                          </div>

                                                        </div>
                                                        <!--<div class="col-lg-12">
              <label>Extension:</label>
              <input matInput placeholder="" type="text" formControlName="extension">
      </div>-->
                                                        <!--<div class="col-lg-12">
              <label>Fax:</label>
              <input matInput placeholder="" type="text" formControlName="fax">
      </div>-->
                                                        <div class="col-lg-12">
                                                          <label>Mobile:</label>
                                                          <input matInput placeholder=""  (keyup)="removeMaskFormat($event,'mobilePhone')" type="tel" formControlName="mobilePhone" mask="(000) 000-0000">
                                                          <mat-error class="cus-error" *ngIf="profileForm.get('mobilePhone')?.touched && profileForm.get('mobilePhone').errors?.minlength">
                                                            <span class="">
                                                              Mobile must have 10 digits.
                                                            </span>
                                                          </mat-error>
                                                        </div>
                                                        <!--<div class="col-lg-12">
              <label>Role:</label>
              <input matInput placeholder="" type="text"   readonly>
      </div>-->
                                                        <div class="col-lg-12">
                                                          <div class="profiles-right-btn update-profile">
                                                            <button mat-flat-button color="primary"
                                                                    class="fs-button">
                                                              Update
                                                              Profile
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>


                                                    <div class="col-lg-6 tree-view-profile" *ngIf="isClient">
                                                      <div class="company-list">Company Profile</div>
                                                      <div class="company-list-name">{{profileName}}</div>
                                                      <div class="company-list">Client Admin Detail</div>
                                                      <div class="row ">
                                                        <div class="col-lg-12">
                                                          <div class="client-admin-info-bg">
                                                            <ag-grid-angular class="ag-theme-balham" [rowData]="rowData$ | async"
                                                                             [columnDefs]="columnDefs$ | async" [sideBar]="false" [floatingFilter]="false" (gridReady)="onGridReady($event)">
                                                            </ag-grid-angular>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="company-list">Company List</div>
                                                      <ngx-treeview [config]="config"
                                                                    [items]="items" *ngIf="!(isSpeener$ | async)">
                                                      </ngx-treeview>
                                                      <div class="spinner-grow text-danger" role="status" *ngIf="(isSpeener$ | async)">
                                                        <span class="sr-only">Loading...</span>
                                                      </div>


                                                    </div>
                                                  </div>
                                                </form>
                                        </mat-tab>
                                        <mat-tab label="Change Password">
                                                        <form [formGroup]="changePasswordForm" novalidate (ngSubmit)="changePassword()">
                                                          <div class="row">
                                                            <div class="col-lg-6">
                                                              <div class="row">
                                                                <div class="col-lg-12">
                                                                  <label class="required-field">Old Passowrd:</label>
                                                                  <input class="passINput" matInput placeholder="" type="{{oldPassText}}" formControlName="oldPassword">
                                                                  <mat-icon color="primary" class="show-password" (click)="oldPass()">
                                                                    remove_red_eye
                                                                  </mat-icon>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                  <label class="required-field">New Passowrd:</label>
                                                                  <input class="passINput" matInput placeholder="" type="{{newPassText}}" formControlName="newPassword" pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#[~$@()$!%*?&<>^+])[A-Za-z\d$#[~@()$!%*?&<>^+].{7,}">
                                                                  <mat-icon color="primary" class="show-password" (click)="newPass()">
                                                                    remove_red_eye
                                                                  </mat-icon>
                                                                  <br />
                                                                  <mat-error *ngIf="passwordLabel.errors" class="cus-error">
                                                                    <span *ngIf="passwordLabel.touched &&  passwordLabel.errors?.required">
                                                                      Password field is required.
                                                                    </span>
                                                                    <span *ngIf="!passwordLabel.errors?.minlength && passwordLabel.errors?.pattern">
                                                                      At least one uppercase letter, one lowercase letter, one number and one special character (Except: / \ : ; " ').
                                                                    </span>
                                                                    <span *ngIf="passwordLabel.errors?.minlength">
                                                                      Password field  Minimum eight characters required.
                                                                    </span>
                                                                    <span *ngIf="passwordLabel.errors?.maxlength">
                                                                      Password field  Maximum 256 characters required.
                                                                    </span>
                                                                  </mat-error>

                                                                </div>
                                                                <div class="col-lg-12">
                                                                  <label class="required-field">Confirm:</label>
                                                                  <input class="passINput" matInput placeholder="" type="{{confirmPassText}}" formControlName="confirmPassword" pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#[~$@()$!%*?&<>^+])[A-Za-z\d$#[~@()$!%*?&<>^+].{7,}">

                                                                  <mat-icon color="primary" class="show-password" (click)="confirmPass()">
                                                                    remove_red_eye
                                                                  </mat-icon>
                                                                  <br />
                                                                  <mat-error *ngIf="confirmPasswordLabel.errors" class="cus-error">
                                                                    <span *ngIf="confirmPasswordLabel.touched && confirmPasswordLabel.errors?.required" >
                                                                      Confirm Password field is required.
                                                                    </span>
                                                                    <span *ngIf="!confirmPasswordLabel.errors?.minlength && confirmPasswordLabel.errors?.pattern">
                                                                      At least one uppercase letter, one lowercase letter, one number and one special character (Except: / \ : ; " ').
                                                                    </span>
                                                                    <span *ngIf="confirmPasswordLabel.errors?.minlength ">
                                                                      Confirm Password field  Minimum eight characters required.
                                                                    </span>
                                                                  </mat-error>
                                                                  <mat-error  class="cus-error" *ngIf="!confirm() && !changePasswordForm.controls['confirmPassword']?.errors?.pattern">
                                                                    Password and confirm password does not match.
                                                                  </mat-error>
                                                                </div>
                                                                <div class="col-lg-12 mt-2">
                                                                  <div class="profiles-right-btn">
                                                                    <button style="width: 320px;" mat-flat-button color="primary"
                                                                            class="fs-button" [disabled]="changePasswordForm.invalid">
                                                                      Change
                                                                      Password
                                                                    </button>
                                                                    <br />
                                                                    <span style="color:darkblue">
                                                                      Your Password must have at least one uppercase letter, one lowercase letter, one number, one special character (Except: / \ : ; " ')
                                                                      and at least 8 character.
                                                                    </span>
                                                                  </div>

                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                </form>
                                        </mat-tab>
                                </mat-tab-group>

                        </div>
                </div>
        </div>
</div>
