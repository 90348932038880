import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { INgxSelectOption } from 'ngx-select-ex';
import { Observable, of } from 'rxjs';
import { Validations } from '../../../constants/validations';
import { FormService } from '../../../services/forms/form.service';
import { Helper } from '../../classes/helpers.class';
import { StateInterface } from '../../interface/tab-interface/state.interface';

@Component({
  selector: 'app-closing-agent-contact',
  templateUrl: './closing-agent-contact.component.html',
  styleUrls: ['./closing-agent-contact.component.css']
})
export class ClosingAgentContactComponent implements OnInit {

  stateList$: Observable<StateInterface>;
  helper: Helper = new Helper();
  closingAgentContactForm: FormGroup;
  submited = false;
  validMessage = Validations;
  mode = '';
  contactObj = {};

  constructor(public dialogs: MatDialog,
    public fs: FormService,
    private dialogRef: MatDialogRef<ClosingAgentContactComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {


    this.mode = (this.data.isEditMode) ? 'Update' : 'Add';
    this.closingAgentContactForm = fs.closingAgentContactForm();
    //this.closingAgentContactForm.controls.closingAgentInfo.disable();

    //set form data
    if (this.data.inputObj !== false) {
      this.contactObj = this.data.inputObj['data'];
    }

    //Set Closing Agent email
    if (this.data.closingAgentEmail != undefined) {
      this.closingAgentContactForm.patchValue({
        email: this.data.closingAgentEmail
      });
      this.closingAgentContactForm.updateValueAndValidity();
    }


  }

  ngOnInit(): void {
    if (this.data.inputObj !== false) {
      this.closingAgentContactForm.patchValue({
        firstName: this.contactObj['firstName'],
        lastName: this.contactObj['lastName'],
        email: this.contactObj['email'],
        phone: this.contactObj['phone']
      })

      this.closingAgentContactForm.updateValueAndValidity();
    }
  }


  formCheck() {
    let isFormValid = false;
    if (this.closingAgentContactForm.valid) {
      isFormValid = true;
    }

    if (!this.closingAgentContactForm.valid) {
      for (let i in this.closingAgentContactForm.controls) {
        this.closingAgentContactForm.controls[i].markAsTouched();
      }

      isFormValid = false;
    }
    return isFormValid;
  }



  addContact() {
    this.submited = true;

    let isValid = this.formCheck();

    if (!isValid) { return; }

    const addObj = this.helper.formData(this.closingAgentContactForm);
    this.close(addObj);
  }

 
 
  get contactForm() {
    return this.closingAgentContactForm.controls;
  }

  chkPhone(event) {
    let realval = this.closingAgentContactForm.get('phone').value;
    if (realval == "") {
      event.target.value = "";
    }
  }
   close(obj) {
    this.dialogRef.close(obj);
  }

}
