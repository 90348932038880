import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientGuard } from './auth/gaurds/client.guard';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { AdminGuard } from './auth/gaurds/admin.guard';
/* tslint:disable */

const appRoutes: Routes = [

	{
		path: 'admin',
		canLoad: [AdminGuard],
		loadChildren: () => import('./modules/admin/admin.module').then(mod => mod.AdminModule)

	},
	{
		path: 'client',
      canLoad: [ClientGuard],
		loadChildren: () => import('./modules/client/client.module').then(mod => mod.ClientModule),
		data: { preload: false }
	},
	{
		path: '',
		loadChildren: () => import('./core/core.module').then(mod => mod.CoreModule),
		data: { preload: false }
	},

	{ path: '**', component: NotFoundComponent }
];

const isIframe = window !== window.parent && !window.opener;


@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { initialNavigation: !isIframe })],
	exports: [RouterModule],
})
export class AppRoutingModule { }
