import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse
} from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { constant } from "../constants/const";
import { Validations } from '../constants/validations';
import { Utility } from '../async/utility';
import { Router } from '@angular/router';
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  public isRander = false;
  constructor(private toastr: ToastrService,
    private utilApi: Utility,
    private cookieService: CookieService,
    public router: Router) {

  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {


    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {

        //remove all toast exept last one
        let self = this;
        if (this.toastr.toasts.length > 0) {
          for (let i = 0; i < this.toastr.toasts.length; i++) {
            let toastId = self.toastr.toasts[i].toastId;
            if (self.toastr.toasts[i].message.indexOf('session has expired') == -1) {
              self.toastr.remove(toastId);
            }
          }
        }


        if (error.status == 0) {
          this.toastr.clear();
          this.toastr.error(Validations.Message.serverNotFound);
          //this.utilApi.setLogout();
        } else if (error.status == 402 || error.status == 401) {
          let self = this;
          if(window.location.pathname != '/login'){
            self.logoutOperation();
            window.location.href = '/login';
          }
        } else if (error.status == 403) {
          let self = this;
          if(window.location.pathname != '/login'){
            self.logoutOperation();
            window.location.href = '/login';
          }
        }

        return throwError(error);
      })
    );
  }
  logoutOperation(){
      this.cookieService.deleteAll('/');
      localStorage.removeItem("userKey");
      localStorage.removeItem("ng2Idle.main.expiry");
      localStorage.removeItem("userKey_b2c");
      localStorage.removeItem("displayName");
      localStorage.removeItem("currentUser");
      localStorage.removeItem("userType");
      localStorage.removeItem("token");
      localStorage.removeItem("userDetailsLocal");
      localStorage.removeItem("surname");
  }
}
