import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { CoreComponent } from './core.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RouterModule } from '@angular/router';
import { CoreRoutingModule } from './core.routing';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotUsernameComponent } from './forgot-username/forgot-username.component'

import { SharedMaterialModule } from '../shared/material/material.module';
import { PocComponent } from './poc/poc.component';
import { httpInterceptorProviders } from '../intercepter';
import { HomeComponent } from './home/home.component';

import { NgxAdalModule } from 'ngx-adal-8';
import { environment } from 'src/environments/environment';
import { MsalService } from '../services/msal.service';
import { UserTypeComponent } from './user-type/user-type.component';
import { CommonModule } from '@angular/common';
import { ResetPassComponent } from './reset-pass/reset-pass.component';
import { PassRestoreComponent } from './pass-restore/pass-restore.component';
import { BlankRedirectComponent } from './blank-redirect/blank-redirect.component';

@NgModule({

  declarations: [
    CoreComponent, NotFoundComponent, ForgotPasswordComponent, ForgotUsernameComponent,
    NotFoundComponent,
    LoginComponent,
    ForgotPasswordComponent,
    PocComponent, HomeComponent,
    UserTypeComponent,
    ResetPassComponent,
    PassRestoreComponent,
    BlankRedirectComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    CoreRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedMaterialModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      newestOnTop: false,
      autoDismiss: true,
      preventDuplicates: true,
      timeOut: 4000,
      extendedTimeOut: 8000,
      positionClass: 'toast-top-right'
    })
  ],
  exports: [SharedMaterialModule],
  entryComponents: [ResetPassComponent],
  providers: [httpInterceptorProviders, MsalService]
})
export class CoreModule { }
