import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, Validators } from '@angular/forms';
import { FormService } from 'src/app/services/forms/form.service';
import { Validations } from 'src/app/constants/validations';
import { Helper } from 'src/app/shared/classes/helpers.class';
import { Utility } from 'src/app/async/utility';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { ToastrService } from 'ngx-toastr';
import { ClientService } from '../../../client.service';
//import { setTimeout } from 'timers';

@Component({
  selector: 'app-add-additional-borrower',
  templateUrl: './add-additional-borrower.component.html',
  styleUrls: ['./add-additional-borrower.component.css']
})
export class AddAdditionalBorrowerComponent implements OnInit {

  isEditMode: boolean = false;
  formData: any;
  formMode: any;
  isTImer: any;
  seconds: any;
  sessionExp: any;
  azureTime: any;
  borrwrArr: any;
  borrowerTypeArr$: Observable<any>;
  //borrowerTypeArr$: any;
  brwrType: any;
  selBrwrType: any;
  borrowerIdInEdit: any;
  selBrwrObj: any;
  helper: Helper = new Helper();
  borrowerForm: FormGroup;
  validMessage = Validations;
  transId: any;
  editObj: any;
  isNameRequired: any;

  constructor(public fs: FormService,
    private clientApi: ClientService,
    public shareUtil: Utility,
    public loader: NgxUiLoaderService,
    public toast: ToastrService,
    public route: ActivatedRoute,
    private dialogRef: MatDialogRef<AddAdditionalBorrowerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    let self = this;
    this.isNameRequired = true;
    this.borrowerForm = this.fs.additionalBorrowerForm();
    this.isEditMode = data.isEditMode;
    this.isTImer = data.isTImer;
    this.borrwrArr = [];
    //this.borrowerTypeArr$ = [{ id: 1, type: 'Individual' }, { id: 2, type: 'Trust' }, { id: 3, type: 'Entity' }];
   
    this.brwrType ='';

    //this.borrowerForm.get('BorrowerCategoryId').setValue('Individual');




    if (data.transId) {
      this.transId = data.transId;
    }

    if (this.isTImer) {
      this.seconds = data.timeSecond;
    }
    //For Azure 1 Hour timeout
    this.azureTime = data.azureTime;
    if (data.sessionExp != undefined) {
      this.sessionExp = data.sessionExp;
    }

    if (data.isEditMode) {   //Edit additional borrower
      let value = data.rowObj;
      this.formMode = 'Update';
      //this.editObj = data.rowObj;
      this.brwrType = value['borrowerCategoryName'];
      this.selBrwrObj = value['borrowerCategoryId'];
      this.borrowerForm.patchValue({
        borrowerCategoryId: (value['borrowerCategoryId']) ? value['borrowerCategoryId'] : "",
        firstName: (value['firstName']) ? value['firstName'] : "",
        middleName: (value['middleName']) ? value['middleName'] : "",
        lastName: (value['lastName']) ? value['lastName'] : "",
        suffix: (value['suffix']) ? value['suffix'] : "",
        isNonBorrowerParty: (value['isNonBorrowerParty']) ? value['isNonBorrowerParty'] : false,
        isGuarantor: (value['isGuarantor']) ? value['isGuarantor'] : false,
        companyName: (value['companyName']) ? value['companyName'] : "",
        formation: (value['formation']) ? value['formation'] : "",
        phone: (value['phone']) ? value['phone'] : "",
        dba: (value['dba']) ? value['dba'] : ""
      });

      this.borrowerForm.updateValueAndValidity();

      this.borrowerIdInEdit = value['borrowerCategoryId'];
      this.borrowerChange(this.selBrwrObj,true);
      //this.brwrType = this.getBrwrNameById(value['borrowerCategoryId']);

      setTimeout(function () {
        if (self.brwrType == 'Individual') {
          self.isNameRequired = true;
          self.borrowerForm.get('companyName').setValidators(null);
          self.borrowerForm.get('firstName').setValidators([Validators.required]);
          self.borrowerForm.get('lastName').setValidators([Validators.required]);

        } else {
          self.isNameRequired = false;
          self.borrowerForm.get('firstName').setValidators(null);
          self.borrowerForm.get('lastName').setValidators(null);
          self.borrowerForm.get('companyName').setValidators([Validators.required]);
        }
        self.borrowerForm.get('companyName').updateValueAndValidity();
        self.borrowerForm.get('firstName').updateValueAndValidity();
        self.borrowerForm.get('lastName').updateValueAndValidity();

      }, 1000);

    } else { //Add additional borrower
      this.formMode = 'Add';
      this.brwrType = 'Individual';
      this.selBrwrObj = 1;
    }

  }


  getBrwrNameById(id) {

    let selObj = this.borrwrArr.filter(function (obj) { return (obj.borrowerCategoryId == id); });
    if (selObj.length > 0) {
      return selObj[0]['borrowerCategoryName'];
    } else {
      return "";
    }
    
  }

  //compareFnBrwrType(option1, option2) {
  //  return option1.borrowerCategoryId === option2.borrowerCategoryId;
  //}
  compareFnBrwrType(o1: any, o2: any): boolean {
    return o1.borrowerCategoryId === o2.borrowerCategoryId;
  }

  ngOnInit() {
    let self = this;
    if (!this.isTImer && !this.isEditMode) {
      let companyNameField = this.borrowerForm.get('companyName');
      companyNameField.setValidators(null);
      this.borrowerForm.get('companyName').setValue(null);
      companyNameField.updateValueAndValidity();
    }

    //get borrwer category
    let userKey = self.shareUtil.getUserId();
    //this.loader.stopAll();
    if (!this.isTImer) {
      this.loader.start();
    }
    if (userKey) {
      this.clientApi.GetBorrowerCategoryList(userKey).subscribe(res => {
        if (res.value) {
          self.borrowerTypeArr$ = of(res['value']);
          self.borrwrArr = res['value'];
          if (res.value.length > 0) {
            if (!self.isEditMode) {
              //self.selBrwrObj = res.value[0].borrowerCategoryId;
              //self.brwrType = res.value[0].borrowerCategoryName;
            }
          }
        }
        if (!this.isTImer) {
          self.loader.stop();
        }

      }, error => {
        this.loader.stop();
        this.toast.error("Internal server error..");
      });
    }
  }

  close(obj) {
    this.dialogRef.close(obj);
  }

  add() {

    if (this.brwrType == 'Individual') {
      this.isNameRequired = true;
      this.borrowerForm.get('companyName').setValidators(null);
      this.borrowerForm.get('firstName').setValidators([Validators.required]);
      this.borrowerForm.get('lastName').setValidators([Validators.required]);

    } else {
      this.isNameRequired = false;
      this.borrowerForm.get('firstName').setValidators(null);
      this.borrowerForm.get('lastName').setValidators(null);
      this.borrowerForm.get('companyName').setValidators([Validators.required]);
    }
    this.borrowerForm.get('companyName').updateValueAndValidity();
    this.borrowerForm.get('firstName').updateValueAndValidity();
    this.borrowerForm.get('lastName').updateValueAndValidity();





    if (!this.borrowerForm.valid) {
      for (let i in this.borrowerForm.controls) {
        this.borrowerForm.controls[i].markAsTouched();
      }
      return;
    }

    let addObj = this.helper.formData(this.borrowerForm);
    addObj['borrowerCategoryName'] = this.brwrType;
    this.close(addObj);
  }

  borrowerChange(eventVal,isEdit) {
    console.log("in br type", eventVal);
    //this.borrowerForm.reset();
    let selValue = eventVal;
    if (!isEdit) {
      this.brwrType = this.getBrwrNameById(selValue);
    }

    //Dynamic validation
    let companyNameField = this.borrowerForm.get('companyName');
    if (this.brwrType != 'Entity') {
      this.borrowerForm.get('phone').setValue(null);
      this.borrowerForm.get('dba').setValue(null);
    }


    if (this.brwrType == 'Individual') {
      this.isNameRequired = true;
      companyNameField.setValidators(null);
      this.borrowerForm.get('companyName').setValue(null);
      this.borrowerForm.get('formation').setValue(null);
      this.borrowerForm.get('phone').setValue(null);
      this.borrowerForm.get('dba').setValue(null);

      this.borrowerForm.get('firstName').setValidators([Validators.required]);
      this.borrowerForm.get('lastName').setValidators([Validators.required]);

    } else {
      this.isNameRequired = false;
      this.borrowerForm.get('firstName').setValidators(null);
      this.borrowerForm.get('lastName').setValidators(null);
      companyNameField.setValidators([Validators.required]);
    }
    companyNameField.updateValueAndValidity();
    this.borrowerForm.get('firstName').updateValueAndValidity();
    this.borrowerForm.get('lastName').updateValueAndValidity();
  }

  chkPhone(event) {
    let realval = this.borrowerForm.get('phone').value;
    if (realval == "") {
      event.target.value = "";
    }
  }

  get brwrFormCtrl() { return this.borrowerForm.controls; }

  get firstName() {
    return this.borrowerForm.get('firstName');
  }
  get lastName() {
    return this.borrowerForm.get('lastName');
  }
  get middleName() {
    return this.borrowerForm.get('middleName');
  }

  sendRes(flag) {
    this.close(flag);
  }

  sendToLogin() {
    //redirect to login
    this.close(false);
  }

  closeDialog() {
    this.close({});
  }

}
