import { Component, OnInit } from '@angular/core';
import { MsalService } from 'src/app/services/msal.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Utility } from 'src/app/async/utility';
import { SharedService } from 'src/app/services/shared.service';
import { ToastrService } from 'ngx-toastr';
import { ResetPassComponent } from '../reset-pass/reset-pass.component';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { Validations } from 'src/app/constants/validations';
import { CookieService } from "ngx-cookie-service";
import { USER } from 'src/app/constants/const';
import jwt_decode from "jwt-decode";


@Component({
  selector: 'app-user-type',
  templateUrl: './user-type.component.html',
  styleUrls: ['./user-type.component.css']
})
export class UserTypeComponent implements OnInit {
  public errorMessage;
  public isFailed = false;
  public valueObj;
  public userId;
  constructor(
    private loader: NgxUiLoaderService,
    private msalService: MsalService,
    private router: Router,
    private route: ActivatedRoute,
    public utilService: Utility,
    public sharedApi: SharedService,
    public toaster: ToastrService,
    private toastr: ToastrService,
    private cookieService: CookieService,
    public dialogs: MatDialog) {
    this.utilService.isLoad(true);
  }

  ngOnInit() {
    let self = this;
    self.utilService.isLoad(true);

    //console.log("in user type:", self.isLoggedIn());
    self.loader.start();
    // setTimeout(() => {
    //   // self.msalService.getAccessData();
    // var cookieResponce = this.cookieService.get(USER);
    // const decodedUser: any = jwt_decode(cookieResponce);

    //   //Delay for US server to get Token
    //   setTimeout(() => {
    //     if (self.isLoggedIn()) {
    //       self.getUserDetails();
    //     } else {
    //       self.router.navigate(['/login']);
    //       self.utilService.isLoad(false);
    //     }
    //   }, 1000);

    // }, 5000);
    var cookieResponce = this.cookieService.get(USER);
    if (!cookieResponce || cookieResponce == '') {
      if(window.location.pathname != '/login'){
        self.msalService.logout();
        window.location.href = '/login';
      }
      return;
    }
    const decodedUser: any = jwt_decode(cookieResponce);
    this.utilService.isLoad(true);
    if (decodedUser && decodedUser.user_id) {
      this.getUserDetails();
    }
    else {
      if(window.location.pathname != '/login'){
        window.location.href = '/login';
      }
      this.utilService.isLoad(false);
    }
  }

  setToastMsg() {
    //remove all toast exept last one
    let self = this;
    if (this.toaster.toasts.length > 0) {
      for (let i = 0; i < this.toaster.toasts.length; i++) {
        let toastId = self.toaster.toasts[i].toastId;
        //if (self.toaster.toasts[i].message.indexOf('session has expired') == -1) {
        self.toaster.remove(toastId);
        //}
      }
    }
  }





  getUserDetails() {
    let self = this;
    let userDetails = self.msalService.getUser();
    var cookieResponce = this.cookieService.get(USER);
    if (!cookieResponce || cookieResponce == '') {
      if(window.location.pathname != '/login'){
        self.msalService.logout();
        window.location.href = '/login';
      }
      return;
    }
    const decodedUser: any = jwt_decode(cookieResponce);
    let reqObj = {
    }
    if (decodedUser && decodedUser.user_id) {
      reqObj['userKey'] = decodedUser.user_id;
    }
    self.loader.start();
    self.sharedApi.getUserDetails(reqObj).subscribe(value => {
      if (value) {
        //self.afterLoginData(value, userDetails);
        if ((value['value']["roleName"] == 'Client Manager') || (value['value']["roleName"] == 'Client Admin Manager') || (value['value']["roleName"] == 'Client Staff')) {


          let reqObj_ = {
            state: "",
            userKey: value['value']["id"],
            clientKey: "1234"
          }


          let getData = self.sharedApi.clientSearch(reqObj_);
          getData.subscribe(_value => {
            getData = null;
            if (!_value['value']) {
              self.errorMessage = "No client is associated with the user.";
              this.setToastMsg();
              self.toaster.warning(self.errorMessage);
              setTimeout(() => {
                self.router.navigate(['/login']);
              }, 1500);
            } else {
              self.afterLoginData(value, userDetails);
            }
            self.loader.stop();
          }, error => {
            self.loader.stop();
            this.errorHandler(error)
          });

        }
        else if (value['value']['roleName'] == 'FS Admin') {
          localStorage.setItem('userType', "1");
          self.router.navigate(['/admin/dashboard']);
        }
        else {
          self.errorMessage = "Access Denied!";
          this.setToastMsg();
          self.toaster.warning(self.errorMessage);
          self.router.navigate(['/login']);
        }


      }

    }, error => {
      self.loader.start();
      setTimeout(() => {
        self.sharedApi.getUserDetails(reqObj).subscribe(value => {
          if (value) {
            self.afterLoginData(value, userDetails);
          }

        }, error => {
          self.loader.stop();
          self.errorMessage = "This User not allocated to Guardian. Please contact with admin.";
          this.setToastMsg();
          self.toaster.warning(self.errorMessage);
          setTimeout(() => {
            self.router.navigate(['/login']);
          }, 2000);
        })
      }, 5000)

    })
  }



  afterLoginData(value, userDetails) {
    let self = this;
    if (value && (value['status'] == 'Success') || value['status'] == 'success') {

      // Check User Associted with Any Profile
      // Upload
      if (value && value.hasOwnProperty("message") && value['message'] == "User Profile does not have access to Guardian Client Portal") {
        this.setToastMsg();
        this.toaster.warning(value['message']);
        // // localStorage.clear();
        setTimeout(function () {
          if(window.location.pathname != '/login'){
            self.msalService.logout();
            window.location.href = '/login';
          }
        }, 1500);
        self.isFailed = true;
        // self.router.navigate(['/login']);
        return;
      }
      localStorage.setItem('userKey', value['id']);
      localStorage.setItem('userKey_b2c', userDetails["UserIdB2C"]);
      localStorage.setItem('userDetailsLocal', JSON.stringify(value['value']));
      self.isFailed = false;
      //self.errorMessage = "";
      //self.roleManage(value);
      setTimeout(() => {
        localStorage.setItem('userKey', value['value']['id']);
        localStorage.setItem('userKey_b2c', userDetails["UserIdB2C"]);
        localStorage.setItem('userDetailsLocal', JSON.stringify(value['value']));
      }, 0)
      this.valueObj = value;
      if (value && value['value'] && value['value']['isPassReset'] || value['value']['isPassReset'] != 0) {
        self.loader.stop();
        self.openDialog();
      } else {
        self.loader.stop();
        self.isFailed = false;
        self.errorMessage = "";
        self.roleManage(value)
      }
    } else {
      self.loader.stop();
      self.errorMessage = "This User not allocated to Guardian. Please contact with admin.";
      this.setToastMsg();
      self.toaster.warning(self.errorMessage);
      setTimeout(function () {
        // window.location.href = '/login';
        if(window.location.pathname != '/login'){
          self.msalService.logout();
          window.location.href = '/login';
        }
      }, 1500);
      // // localStorage.clear();
      self.isFailed = true;
      // self.router.navigate(['/login']);
    }
  }

  roleManage(response) {
    let self = this;
    let unAuthorisedMessage = "Your are not authorized user to access this portal.";
    this.setToastMsg();
    if (response && response['status'] == 'Success') {
      if (response.hasOwnProperty("value")) {
        // Redirection
        // Lender
        localStorage.setItem('userKey', response['value']['id']);
        if (response['value']['roleName'] == 'Client Manager' || response['value']['roleName'] == 'Client Staff' || response['value']['roleName'] == 'Client Admin Manager') {
          localStorage.setItem('userType', "1");
          self.router.navigate(['/client/dashboard']);
        } else {
          //Commenting for production release
          if (response['value']['roleName'] == 'FS Admin' || response['value']['roleName'] == 'FS Staff') {
            localStorage.setItem('userType', "1");
            self.router.navigate(['/admin/dashboard']);
          }
          else {
            self.toaster.warning(unAuthorisedMessage);
            setTimeout(() => {
              self.router.navigate(['/login']);
            }, 1000);

          }
          //self.toaster.warning(unAuthorisedMessage);
          //setTimeout(() => {
          //  self.router.navigate(['/login']);
          //}, 1000);
          //localStorage.setItem('userType', "2");
          // this.router.navigate(['/admin/dashboard']);
        }
      } else {
        self.toaster.warning(unAuthorisedMessage);
        self.router.navigate(['/login']);
      }
    } else {
      self.toaster.warning(unAuthorisedMessage);
      self.router.navigate(['/login']);
    }
  }


  openDialog() {
    let self = this
    let dialogRef = self.dialogs.open(ResetPassComponent, {
      disableClose: true,
      width: '820px'
    });

    // After added and valided form then is reutrn called it.
    dialogRef.afterClosed().pipe(
      filter(res => res)
    ).subscribe(res => {
      if (res) {
        if (res && res['status'] == true) {
          self.roleManage(this.valueObj)
        }
      }
    });
  }


  getUsers() {
    let self = this;
    return JSON.stringify(localStorage.getItem('currentUser'));
  }


  isLoggedIn() {
    let self = this;
    return self.msalService.isLoggedIn();
  }

  async errorHandler(err) {
    console.error(err)
  }


  async success(res = [], type = null) {

  }


  login() {
    let self = this;
    self.router.navigate(['/login']);
    //this.utilApi.setLogout();
    // this.msalService.logout();
  }

}
