<div *ngIf="!isTImer">
  <h2 mat-dialog-title>{{formMode}} Additional Borrower</h2>
  <form [formGroup]="borrowerForm" novalidate (ngSubmit)="add()">
    <mat-dialog-content class="mat-typography addNewcl">
      <div class="row">
        <div class="col-12">
          <label class="required-field typeDD">
            Borrower Type:
          </label>
          <mat-select (selectionChange)="borrowerChange($event.value,false)"
                      [(ngModel)]="selBrwrObj"
                      formControlName="borrowerCategoryId">
            
            <!--<mat-option value="">-- Select --</mat-option>-->
            <mat-option *ngFor="let type of borrowerTypeArr$ | async"
                        [value]="type.borrowerCategoryId">
              {{type.borrowerCategoryName}}
            </mat-option>
          </mat-select>


          <!--<mat-select [value]="uiStateSvc.currentClient$ | async" [compareWith]="compareFn">
            <mat-option *ngFor="let client of store.authorizedClients$ | async" [value]="client"
                        (onSelectionChange)="changeCurrentClient($event, client)">
              {{client.name}}
            </mat-option>
          </mat-select>-->


          <!--<mat-select placeholder="Toppings" [compareWith]="compareFn" [(value)]="toppings">
    <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping.name}}</mat-option>
  </mat-select>-->

        </div>

        <div class="col-12">
          <div>

            <label [ngClass]="{'required-field': isNameRequired}">First Name:</label>
            <input matInput placeholder="" autocomplete="off" type="text" formControlName="firstName" required>
            <div *ngIf="firstName?.touched && firstName.errors">
              <mat-error class="error-cus" *ngIf="firstName.errors?.required">
                {{validMessage.Message.firstNameRequired}}
              </mat-error>
            </div>
          </div>

          <div>
            <label>Middle Name:</label>
            <input matInput placeholder="" autocomplete="off" type="text" formControlName="middleName">
          </div>

          <div class="">

            <label [ngClass]="{'required-field': isNameRequired}">Last Name:</label>
            <input matInput placeholder="" autocomplete="off" type="text" formControlName="lastName" required>
            <div *ngIf="lastName?.touched && lastName.errors">
              <mat-error class="error-cus" *ngIf="lastName.errors?.required">
                {{validMessage.Message.lastNameRequired}}
              </mat-error>
            </div>
          </div>
          <div class="">
            <label>Suffix:</label>
            <input matInput placeholder="" autocomplete="off" type="text" formControlName="suffix">
          </div>



          <div class="">
            <div class="brwCheckbox">
              <label class="pt-1 opt1">Non-Borrowing Party:</label>
              <input formControlName="isNonBorrowerParty" type="checkbox"
                     name="nonBrwrChk"
                     class="FSchkboxCustom">
            </div>
            <div class="brwCheckbox">
              <label class="pt-1 opt2">Is Guarantor:</label>
              <input formControlName="isGuarantor" type="checkbox"
                     name="isGuarChk"
                     class="FSchkboxCustom">
            </div>
          </div>

        </div>  <!--Common field-->

        <div *ngIf="(brwrType != 'Individual')" class="col-12">
          <div>
            <label *ngIf="(brwrType == 'Trust')" class="required-field">Trust Name:</label>
            <label *ngIf="(brwrType != 'Trust')" class="required-field">Company Name:</label>

            <input matInput placeholder="" autocomplete="off" type="text" formControlName="companyName">

            <div *ngIf="brwrFormCtrl.companyName?.touched && brwrFormCtrl.companyName.errors?.required">
              <mat-error class="error-cus">
                <span *ngIf="(brwrType == 'Trust')">Trust name is required.</span>
                <span *ngIf="(brwrType != 'Trust')">Company name is required.</span>
              </mat-error>
            </div>
          </div>

          <div>
            <label>Formation:</label>
            <input matInput placeholder="" autocomplete="off" type="text" formControlName="formation">
          </div>
        </div> <!--Trust/Entity brwr field-->

        <div *ngIf="(brwrType == 'Entity')" class="col-12">
          <div>
            <label>Contact Number:</label>
            <input matInput formControlName="phone" (keyup)="chkPhone($event)"  minlength="10" type="tel" mask="(000) 000-0000">
            <div *ngIf="brwrFormCtrl.phone.touched && brwrFormCtrl.phone.errors?.minlength">
              <mat-error class="error-cus">
                Contact must have 10 digits.
              </mat-error>
            </div>
          </div>
          <div>
            <label>DBA:</label>
            <input matInput placeholder="" autocomplete="off" type="text" formControlName="dba">
          </div>
        </div>



          <div class="col-12">
            <div class="col-lg-12 text-center">
              <div>
                <button type="submit" mat-flat-button color="primary" class="fs-button">
                  {{formMode}}
                </button>
              </div>
            </div>
          </div>
        </div>

</mat-dialog-content>
  </form>
  <button mat-button mat-dialog-close class="popup-close-btn"><i class="material-icons">close</i></button>
</div>


<!--<div *ngIf="isTImer && !sessionExp">

  <h2 mat-dialog-title>Need More Time?</h2>
  <mat-dialog-content class="mat-typography addNewcl">
    Your session is about to expire.
    <span class="d-block"> You will be automatically signed out in</span>
    <span class="timer">00:{{data.timeSecond | async }}</span>
    <span class="d-block">To continue your session, select <b>Stay Signed in</b>.</span>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button type="button" (click)="sendRes(true)" mat-flat-button color="primary" class="fs-button">
      Stay Signed In
    </button>
    <button type="button" (click)="sendRes(false)" mat-flat-button color="primary" class="fs-button">
      Sign out
    </button>
  </mat-dialog-actions>
  <button mat-button class="popup-close-btn" (click)="sendRes(true)"><i class="material-icons">close</i></button>
</div>



<div *ngIf="isTImer && sessionExp && !azureTime">
  <h2 mat-dialog-title>Session Expired</h2>
  <mat-dialog-content class="mat-typography addNewcl">
    Please log in again.
  </mat-dialog-content>
  <mat-dialog-actions>
    <button type="button" (click)="sendToLogin()" mat-flat-button color="primary" class="fs-button">
      Sign in
    </button>
  </mat-dialog-actions>
  <button mat-button class="popup-close-btn" (click)="sendToLogin()"><i class="material-icons">close</i></button>
</div>

<div *ngIf="azureTime">
  <h2 mat-dialog-title>Session Expired</h2>
  <mat-dialog-content class="mat-typography addNewcl">
    Please log in again.
  </mat-dialog-content>
  <mat-dialog-actions>
    <button type="button" (click)="sendToLogin()" mat-flat-button color="primary" class="fs-button">
      Sign in
    </button>
  </mat-dialog-actions>
  <button mat-button class="popup-close-btn" (click)="closeDialog()"><i class="material-icons">close</i></button>
</div>-->
