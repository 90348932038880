<div>
  <h2 mat-dialog-title>Loan Number</h2>
  <mat-dialog-content class="mat-typography addNewcl">
    <div class="row">
      <div class="col-12">
        <label class="required-field typeDD">Loan Number:</label>
        <input minlength="10" type="text" [(ngModel)]="loanNumber">
        <div class="error" *ngIf="loanNumberSubmitted">
          <div *ngIf="loanNumber.trim().length<1 && !loanExists">Please enter Loan Number.</div>
          <div *ngIf="loanExists">The loan number currently exists, to copy the details of the order into a new order enter the loan number and enter a proceeding dash and number (ie: 123456-1).</div>
        </div>
      </div>
      <div class="col-12">
        <div class="col-lg-12 text-center">
          <div>
            <!--<button type="button" (click)="createOrder()" mat-flat-button color="primary" class="fs-button">
              Next
            </button>-->

            <button mat-flat-button color="primary" (click)="createOrder()" *ngIf="!(isSpeener$ | async)">Next</button>
            <div class="spinner-grow text-danger" style="width: 20px; height:20px; margin-left: 4px;" role="status" *ngIf="(isSpeener$ | async) ">
              <span class="sr-only">Loading...</span>
            </div>


          </div>
        </div>
      </div>
     </div>
  </mat-dialog-content>
  <button mat-button mat-dialog-close class="popup-close-btn"><i class="material-icons">close</i></button>
</div>
