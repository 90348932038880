import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'ag-grid-enterprise';
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
if (window.location.host.toLowerCase().indexOf('guardian.fundingshield.com') != -1) {
  window.console.log = function () { };
  window.console.info = function () { };
  window.console.debug = function () { };
  window.console.warn = function () { };
  window.console.error = function () { };
  setTimeout(function () { window.console.clear(); }, 1000);
}
