import { Observable, of as observableOf, of } from 'rxjs';
import { Validators, FormGroup } from '@angular/forms';
import * as _ from 'underscore';
import * as  moment from 'moment';

// export
export class Helper {

 
  /**
   * @memberfunction : `formData`
   * @purpose : `For provided direct data as controls.`
   * @return : `Return value as Asyncronized`
   */
  formData(formData): Observable<any> {
    return formData.hasOwnProperty('value') ? formData['value'] : formData.controls;
  }

  /**
   * @memberof: success
   * @purpose : for get `API is success or not.`
   */
  success(res): boolean {
    return (res.hasOwnProperty('status') && (res.status == 200 ||
      res.status == 201 || res.status == 202 || res.status == "Success")) ? true : false;
  }

  /**
   * @memberof: response
   * @purpose : for get `API is response return.`
   */
  response(res): Observable<any> {
   // return res.hasOwnProperty('response') ? res['response'] : null;
    return res.hasOwnProperty('value') ? res['value'] : null;
  }


  /**
   * 
   * @param file 
   */
  getBase64(file) {
    let me = this;
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (file) {
        let fileStr: any = reader['result'];
        let base64result = fileStr.substr(fileStr.indexOf(',') + 1)
        resolve(base64result);
      };
      reader.onerror = function (error) {
        reject(error)
      };
    })
  }

  /**
   * @append `Class for new`
   */
  appendClass(id, addClass) {
    //setTimeout(()=>{
      const classData = document.getElementsByClassName('client-info-label');
        if(classData && classData.length>0){
          let dynamicId = classData[0]['id'].substring(classData[0]['id'].length-3,classData[0]['id'].length-2);
          id = `mat-tab-label-${dynamicId}-${id.substring(id.length-1)}`;
          let el: HTMLElement = document.getElementById(`${id}`);

         // console.log('appendClass:', el.classList)


          if (el != null) {
            if (Array.from(el.classList).indexOf(addClass) == -1) {
              el.classList.add(`${addClass}`);
            }
          }
          // Static For 9
          //let nineId = `mat-tab-label-${dynamicId}-9`;
          //let nineEl: HTMLElement = document.getElementById(`${nineId}`);
          //if (nineEl) {
          //  nineEl.classList.add(`${addClass}`);
          //}
        }
   
   // },150)
   
  }


  /**
   * @append `Class for remove`
   */
  removeClass(id, removeClass) {
      const classData =document.getElementsByClassName('client-info-label');
        if(classData && classData.length>0){
          let dynamicId = classData[0]['id'].substring(classData[0]['id'].length-3,classData[0]['id'].length-2);
          id = `mat-tab-label-${dynamicId}-${id.substring(id.length-1)}`;
          let el: HTMLElement = document.getElementById(`${id}`);

          //console.log('removeClass:', el.classList)


          if (el!= null) {
            if (Array.from(el.classList).indexOf(removeClass) > -1) {
              el.classList.remove(`${removeClass}`);
            }
          }
        } 
  }

  /**
   * @method : addedClass
   * @purpose : this is added a new class as pass by value.
   */
  addedClass(className, appendClassName, tabIndex = 0) {
    setTimeout(() => {
      className = 'mat-tab-label';
      let classArray = document.getElementsByClassName(`${className}`);

      //console.log('addedClass:', classArray[tabIndex].classList)

      if (classArray[tabIndex] != null) {
        if (classArray.length > 0 && classArray[tabIndex] && classArray[tabIndex].classList) {
          let arr = classArray[tabIndex].className.split(" ");
          //let arr =  Array.from(el.classList).indexOf(addClass) 


          if (arr.indexOf(appendClassName) == -1) {
            classArray[tabIndex].className += " " + appendClassName;
          }
        }
      }


    }, 1000);
  }



  /**
   * @method : addedClass
   * @purpose : this is delete a existing  class as pass by value.
   */
  deleteClass(className, appendClassName, tabIndex = 0) {
    let classArray = document.getElementsByClassName(`${className}`);
    // added Class
    //console.log('deleteClass:', classArray[tabIndex].classList)

    if (classArray[tabIndex] != null) {

      if (classArray.length > 0 && classArray[tabIndex].hasOwnProperty('classList')) {

        if (Array.from(classArray[tabIndex].classList).indexOf(appendClassName) > -1) {
          classArray[tabIndex].classList.remove(`${appendClassName}`);
        }


      }
    }






  }
  /**
   * @Handler : errorHandler
   */
  errorHandler(err = "Internal server error.") {
    // if(err && err.hasOwnProperty('message')){
    //   this.toastr.error(err['message'],'Error!');
    // } else{
    //   this.toastr.error(err,'Error!');
    // }
  }


  /**
   * @Handler : errorHandler
   */
  isNumber(val = null) {
    let patt = new RegExp('^[0-9]{1,}\d*$');
    return (val && val.length > 0 && patt.test(val)) ? true : false;
  }

  /**
   * @method : setDisable
   * @purpose : this @method is  Required for set as Default Value.
   * @for 
   */
  setDisable(setDisableArray = [], formG: FormGroup) {
    if (!_.isEmpty(setDisableArray)) {
      _.each(setDisableArray, (row) => {
        if (!_.isEmpty(formG.get(row))) {
          formG.get(`${row}`).disable();
        }
      });
    }
  }

  /**
   * @method: dateFormate
   * @purpose :  this method used for set date formate as required for sature date picker
   */
  dateFormate(updateDate = null) {
    //When Date Not Getting this block called
    if (!updateDate) {
      return new Date(parseInt(moment().format("YYYY")), parseInt(moment().format("M")) - 1,
        parseInt(moment().format("Do")));
    } else {
      // Date Get When this called.
      let mDate = moment(updateDate, "MM-DD-YYYY");
      return new Date(mDate['_i']);
    }
  }


 /**
  * @method: slaColor
  * @param value 
  * @purpose : used for render dynamic color as per value.
  */
  slaColor(value = 0){
   // For less then equal 0
   if(value<=0){
     return 'btn-danger';
   } else if(value>0 && value<2){
     // For lessthen 2 and greater then 0
     return 'btn-orange';
   }else if(value>=2 && value<4){
    // For lessthen 2 and greater then 0
    return 'btn-warning';
  }else if(value>=4){
    // For lessthen 2 and greater then 0
    return 'btn-primary';
  }
  }

    /**
   * @method: dateFormate
   * @purpose :  this method used for set date formate as required for sature date picker
   */
  dayFormate(updateDate = null) {
    //When Date Not Getting this block called
     console.error(updateDate)
    if (!updateDate) {
      return new Date(parseInt(moment().format("YYYY")), parseInt(moment().format("M")) - 1,
        parseInt(moment().format("Do")));
    } else {
      // Date Get When this called.
      let mDate = moment(updateDate, "MM-DD-YYYY");
      return new Date(mDate['_i']);
    }
  }


  successToasty(obj){
    
    // if(this.toastr){
    //   if(obj && obj.hasOwnProperty('message')){
    //     this.toastr.error(obj['message'], 'success');
    //   } else{
    //     this.toastr.error('successfully operation excute.', 'success');
    //   }
    // }
   
  }

}
export  enum TxnStatusType {
  Mismatch = 0, Validated = 1, Edit = 2,
}

export enum TxnSectionType {
  QFAC = 0, Bank = 1, Underwriter = 2, CPLSign = 3, CPL = 4, CPLForm = 5, Lender = 6, Borrower = 7,
  Proeprty = 8, ClosingAgent = 9, Loan = 10, WireTransaction = 11, Undefined = -1, Rush = 12, CPLRecertification = 13,
}
