import { Action } from '@ngrx/store';
import { ClientInterface } from '../../shared/interface/tab-interface/client-info.interface';
import { BorrowerInterface } from 'src/app/shared/interface/tab-interface/borrower.interface';
import { UploadInterface } from 'src/app/shared/interface/tab-interface/upload.interface';
import { StateInterface } from 'src/app/shared/interface/tab-interface/state.interface';
import { LoanDetailsInfoInterface } from 'src/app/shared/interface/tab-interface/loan-details.interface';
import { WireInfoInterface } from 'src/app/shared/interface/tab-interface/wire_info.interface';


export enum Orders {
  clientOrderUpdate = 'CLIENT_ORDER_UPDATE',
  borrowerOrderUpdate = 'BORROWER_ORDER_UPDATE',
  uploadOrderUpdate = 'UPLOAD_ORDER_UPDATE',
  stateList = 'STATE_LIST',
  get_state = 'GET_STATE',
  updateClosingAgent = '[closing] update closing agent',
  get_order = '[Order] get order',
  rush_orders = '[Order set rush Order]',
  loan_number = '[LoanNumber] loan number',
  propertyInfo = '[Order] get property info.',
  loanDetailsInfo = '[Order] get loan details info.',
  underWriterInfo = '[Order] get insurance list.',
  wireInfo = '[Order] added wire info.',
  contactInfo = '[Contact] info',
  notesInfo = '[Notes] info',
  extraData = '[extra] info'
}
export class ClientOrder implements Action {
  public readonly type = Orders.clientOrderUpdate;
  constructor(public payload: any) {
  }
}

export class BorrowerOrder implements Action {
  public readonly type = Orders.borrowerOrderUpdate;
  constructor(public payload: any) {
  }
}

export class GetStates implements Action {
  
  readonly type = Orders.get_state;
}

export class GetOrder implements Action {
  readonly type = Orders.get_order;
  constructor(public payload: any) {
  }
}

export class UploadOrder implements Action {
  public readonly type = Orders.uploadOrderUpdate;
  constructor(public payload: any) {
  }
}

export class StateList implements Action {
  public readonly type = Orders.stateList;
  constructor(public payload: StateInterface) {
  }
}


// Loan Number
export class LoanNumber implements Action {
  public readonly type = Orders.loan_number;
  constructor(public payload: { loanNumber: any}) {
  }
}

// Update Closing Agent

export class UpdateClosingAgent implements Action {
  public readonly type = Orders.updateClosingAgent;
  constructor(public payload: any) {
  }
}


// Property Info State

export class PropertyState implements Action {
  public readonly type = Orders.propertyInfo;
  constructor(public payload: any) {
  }
}


 // For under writer info 
export class UnderWriterAction implements Action {
   public readonly type = Orders.underWriterInfo;
  constructor(public payload: any) {
  }
}


// Loan Details State Updated.

export class LoanDetailsAction implements Action {
  public readonly type = Orders.loanDetailsInfo;
  constructor(public payload: any) {
  }
}


// Rush Order 
export class RushOrderAction implements Action {
  public readonly type = Orders.rush_orders;
  constructor(public payload: {isRushOrder: boolean}) {

  }
}

// Wire Info added.

export class WireInfoAction implements Action {
  public readonly type = Orders.wireInfo;
  constructor(public payload: any ) {
  }
}


// Contact Info
export class ContactInfoAction implements Action {
  public readonly type = Orders.contactInfo;
  constructor(public payload: any) {
  }
}

// Notes Info
export class NotesInfoAction implements Action {
  public readonly type = Orders.notesInfo;
  constructor(public payload: any) {
  }
}

// ExtraInfo Data...
export class ExtraInfoAction implements Action {
  public readonly type = Orders.extraData;
  constructor(public payload: any) {
  }
}

export type OrderActions = ClientOrder | BorrowerOrder
  | UploadOrder | StateList 
  | UpdateClosingAgent | ContactInfoAction
  | LoanNumber | PropertyState | LoanDetailsAction |
   UnderWriterAction | 
   RushOrderAction |
  WireInfoAction | ExtraInfoAction | NotesInfoAction;
